import PenerimaanMpr from "@/components/mpr/penerimaan/PenerimaanMPR.vue";
import PenerimaanMprCreate from "@/components/mpr/penerimaan/PenerimaanCreate.vue";
import PenerimaanDetail from "@/components/mpr/penerimaan/PenerimaanDetail.vue";
import PenerimaanEdit from "@/components/mpr/penerimaan/PenerimaanEdit.vue";

let routes= [
    
    {
      path: '/',
      meta: {
        title: "Chain System",
      },
      name: "MprPenerimaan",
      component: PenerimaanMpr
    },
    {
      path: "add",
      meta:{
        title: "Chain System",
      },
      name: "MprPenerimaanCreate",
      component:PenerimaanMprCreate
    },
    {
      path: "detail/:id",
      meta:{
        title: "Chain System",
      },
      name: "MprPenerimaanDetail",
      component:PenerimaanDetail
    },
    {
      path: "edit/:id",
      meta:{
        title: "Chain System",
      },
      name: "PenerimaanEdit",
      component:PenerimaanEdit
    },
   ]
  
  export default routes
  