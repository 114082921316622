<template>
  <div>
    <nav class="sidebar">
      <div class="sidebar-header">
        <a v-if="user_type == 'CHAIN'" href="/dashboard" class="sidebar-brand">
          <img src="/assets/images/getsitelogo.png" width="40px" height="40px" /><span> CHAIN </span></a>

        <a v-if="user_type == 'CT'" href="/dashboard-ct" class="sidebar-brand">
          <img src="/assets/images/getsitelogo.png" width="40px" height="40px" /><span> CT </span></a>

        <a v-if="user_type == 'FP'" href="/dashboard-fp" class="sidebar-brand">
          <img src="/assets/images/getsitelogo.png" width="40px" height="40px" /><span> FP </span></a>

        <a v-if="user_type == 'GT'" href="/dashboard-gt" class="sidebar-brand">
          <img src="/assets/images/getsitelogo.png" width="40px" height="40px" /><span> GT </span></a>

        <a v-if="user_type == 'RM'" href="#" class="sidebar-brand">
          <img src="/assets/images/getsitelogo.png" width="40px" height="40px" /><span> RM </span></a>

        <div class="sidebar-toggler not-active">
          <span></span><span></span><span></span>
        </div>
      </div>

      <div class="sidebar-body">
        <ul class="nav">
          <li class="nav-item nav-category">{{ $t("MainTxt") }}</li>

          <li class="nav-item " v-if="user_type == 'CHAIN'">
            <a href="/dashboard" class="nav-link">
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">{{ $t("dashboardMenu") }} </span>
            </a>
          </li>

          <li class="nav-item "
            v-if="user_type == 'CHAIN' && permission.TransportOrderMarginListController?.method_custom5">
            <a href="/margin-list" class="nav-link">
              <i class="link-icon" data-feather="list"></i>
              <span class="link-title">Margin List</span>
            </a>
          </li>

          <template v-if="company_code == 'LINC' || company_code == 'BP'">
            <li class="nav-item" v-if="user_type == 'CHAIN'">
              <a href="/dashboard-fullfilment" class="nav-link">
                <i class="link-icon mdi mdi-chart-line"></i>
                <span class="link-title">Dashboard Fullfilment </span>
              </a>
            </li>
          </template>



          <span v-if="user_type == 'CT'">
            <li class="nav-item">
              <a href="/dashboard-ct" class="nav-link">
                <i class="link-icon" data-feather="box"></i>
                <span class="link-title">{{ $t("dashboardMenu") }} </span>
              </a>
            </li>

            <template v-if="company_code == 'LINC' || company_code == 'BP'">
              <li class="nav-item">
                <a href="/dashboard-fullfilment" class="nav-link">
                  <i class="link-icon mdi mdi-chart-line"></i>
                  <span class="link-title">Dashboard Fullfilment </span>
                </a>
              </li>
            </template>


            <li class="nav-item " v-if="permission.TransportOrderMarginListController?.method_custom5">
              <a href="/margin-list" class="nav-link">
                <i class="link-icon" data-feather="list"></i>
                <span class="link-title">Margin List</span>
              </a>
            </li>

            <template v-if="company_code == 'LINC' || company_code == 'BP'">
              <li class="nav-item">
                <a href="/dashboard-fullfilment-kanban" class="nav-link">
                  <i class="link-icon mdi mdi-chart-bar"></i>
                  <span class="link-title">Fullfilment Kanban</span>
                </a>
              </li>
            </template>

            <li class="nav-item" v-if="permission.TruckUtilityDetailController?.method_custom5
          ">
              <a href="/dashboardUtilitationReport" class="nav-link">
                <i class="link-icon mdi mdi-chart-areaspline"></i>
                <span class="link-title">Utility Dashboard</span>
              </a>
            </li>
            <li class="nav-item" v-if="permission.TruckProductivityController?.method_custom5
          ">
              <a href="/dashboardProductivity" class="nav-link">
                <i class="link-icon mdi mdi-chart-bar"></i>
                <span class="link-title">Productivity Dashboard</span>
              </a>
            </li>
            <li class="nav-item" v-if="permission.VendorAnalystDashboardController?.method_custom5
          ">
              <a href="/vendor-analyst-dashboard" class="nav-link">
                <i class="link-icon mdi mdi-chart-line"></i>
                <span class="link-title">Vendor Analyst Dashboard</span>
              </a>
            </li>
            <!-- <li class="nav-item">
              <a
                href="/one-stop-report"
                class="nav-link"
                v-if="
                  permission.TransportOrderHeaderCtController?.method_custom1
                "
              >
                <i class="link-icon mdi mdi-chart-line"></i>
                <span class="link-title">One Stop Report</span>
              </a>
            </li> -->
            <template v-if="company_code == 'LINC' || company_code == 'BP'">
              <li class="nav-item">
                <a href="/one-stop-report-shipwithreff" class="nav-link" v-if="permission.TransportOrderHeaderCtController?.method_custom1
          ">
                  <i class="link-icon mdi mdi-chart-line"></i>
                  <span class="link-title">One Stop Report </span>
                </a>
              </li>
            </template>
            <template v-if="company_code == 'LINC' || company_code == 'BP'">
              <li class="nav-item">
                <a href="/sequence-driver-summary" class="nav-link" v-if="permission.TransportOrderHeaderCtController?.method_custom1
          ">
                  <i class="link-icon mdi mdi-truck-outline"></i>
                  <span class="link-title">Sequence Driver Summary</span>
                </a>
              </li>
            </template>

            <li class="nav-item" v-if="user_type == 'CT' &&
          permission.DmDashboardController?.method_custom5
          ">
              <a class="nav-link collapsed" data-bs-toggle="collapse" href="#dmDashboarMenu" role="button"
                aria-expanded="false" aria-controls="dmDashboarMenu">
                <i class="link-icon mdi mdi-chart-line"></i>
                <span class="link-title">Dm Dashboard</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-chevron-down link-arrow">
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>
              </a>
              <div class="collapse" id="dmDashboarMenu" style="">
                <ul class="nav sub-menu">
                  <!-- <li class="nav-item">
                  <a href="/rute?params=request" class="nav-link">Request Rute</a>
                </li> -->

                  <li class="nav-item">
                    <a href="/dm-dashboard?params=1" class="nav-link">Driver Active VS Kendaraan</a>
                  </li>

                  <li class="nav-item">
                    <a href="/dm-dashboard?params=2" class="nav-link">Demografi Driver</a>
                  </li>

                  <li class="nav-item">
                    <a href="/dm-dashboard?params=3" class="nav-link">Estimasi selesai Service</a>
                  </li>

                  <li class="nav-item">
                    <a href="/dm-dashboard?params=4" class="nav-link">Active MPR & Aging </a>
                  </li>

                  <li class="nav-item">
                    <a href="/dm-dashboard?params=5" class="nav-link">No Driver Status</a>
                  </li>

                  <li class="nav-item">
                    <a href="/dm-dashboard?params=6" class="nav-link">Deposit, Laka dan Claim</a>
                  </li>


                  <li class="nav-item">
                    <a href="/dm-dashboard?params=7" class="nav-link">LTD KPI</a>
                  </li>

                </ul>
              </div>
            </li>


            <li class="nav-item" v-if="user_type == 'CT' &&
          permission.OpsDashboardController?.method_custom5
          ">
              <a class="nav-link collapsed" data-bs-toggle="collapse" href="#opsDashboarMenu" role="button"
                aria-expanded="false" aria-controls="opsDashboarMenu">
                <i class="link-icon mdi mdi-monitor-dashboard"></i>
                <span class="link-title">OPS Dashboard</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-chevron-down link-arrow">
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>
              </a>
              <div class="collapse" id="opsDashboarMenu" style="">
                <ul class="nav sub-menu">
                  <!-- <li class="nav-item">
                  <a href="/rute?params=request" class="nav-link">Request Rute</a>
                </li> -->

      

                  <li class="nav-item">
                    <a href="/dashboard-kpi?params=8" class="nav-link">KPI Customer</a>
                  </li>

                  <!-- <li class="nav-item">
                    <a href="/dashboard-kpi?params=9" class="nav-link">On Time Pick up</a>
                  </li> -->

                  <li class="nav-item">
                    <a href="/dashboard-kpi?params=10" class="nav-link">On time delivery</a>
                  </li>

                  <li class="nav-item" >
                    <a href="/dashboard-kpi?params=11" class="nav-link">POD Return</a>
                  </li>

                  

                  <li class="nav-item">
                    <a href="/dashboard-kpi?params=12" class="nav-link">Zero Claim</a>
                  </li>

                  <!-- <li class="nav-item">
                    <a href="/dashboard-kpi?params=13" class="nav-link">Kelayakan Kendaraan</a>
                  </li> -->


                 

                </ul>
              </div>
            </li>

            <li class="nav-item nav-category" v-if="company_code == 'LINC' || company_code == 'BP'">
              <i class="mdi mdi-bell"></i> Alert
            </li>
            <li class="nav-item" v-if="permission.RealMonitoringController?.method_custom1
          ">
              <a href="/trip-alert" class="nav-link">
                <i class="link-icon mdi mdi-car-estate"></i>
                <span class="link-title">{{ $t("TripAlertTxt") }}</span>

                <span class="badge rounded-pill bg-primary" style="margin-left: 20px">{{ totalTrip }}</span>
              </a>
            </li>
            <li class="nav-item" v-if="permission.RealMonitoringController?.method_custom2
          ">
              <a href="/doc-alert" class="nav-link">
                <i class="link-icon mdi mdi-content-copy"></i>
                <span class="link-title">{{ $t("DocAlertTxt") }} </span>

                <span class="badge rounded-pill bg-primary" style="margin-left: 20px">{{ totalDoc }}</span>
              </a>
            </li>
            <li class="nav-item" v-if="permission.RealMonitoringController?.method_custom3
          ">
              <a href="/service-alert" class="nav-link">
                <i class="link-icon mdi mdi-tools"></i>
                <span class="link-title">{{ $t("ServiceAlertTxt") }} </span>
                <span class="badge rounded-pill bg-primary" style="margin-left: 20px">{{ totalSA }}</span>
              </a>
            </li>
            <li class="nav-item" v-if="permission.RealMonitoringController?.method_custom3
          ">
              <a href="/gate-alert" class="nav-link">
                <i class="link-icon mdi mdi-boom-gate"></i>
                <span class="link-title">{{ $t("GateAlertTxt") }} </span>
                <span class="badge rounded-pill bg-primary" style="margin-left: 20px">{{ totalGate }}</span>
              </a>
            </li>
            <li class="nav-item" v-if="permission.RealMonitoringController?.method_custom4
          ">
              <a href="/ontime-analysis" class="nav-link">
                <i class="link-icon mdi mdi-clock"></i>
                <span class="link-title">{{ $t("OntimeAnalysisTxt") }} </span>
                <!-- <span
                class="badge rounded-pill bg-primary"
                style="margin-left: 20px"
                >{{totalSA}}</span
              > -->
              </a>
            </li>

            <li class="nav-item nav-category" v-if="permission.TransportOrderHeaderCtController">
              <i class="mdi mdi-clipboard-text"></i>
              {{ $t("transactionData") }}
            </li>
            <li class="nav-item" v-if="permission.TransportOrderHeaderCtController?.method_custom5
          ">
              <a href="/ct-orders" class="nav-link">
                <i class="link-icon" data-feather="shopping-bag"></i>
                <span class="link-title">{{ $t("orders_txt") }}</span>
              </a>
            </li>
            <li class="nav-item" v-if="permission.CustomerOrderTripController?.method_custom5
          ">
              <a href="/customer-trip-orders" class="nav-link">
                <i class="link-icon mdi mdi-truck-delivery"></i>
                <span class="link-title">{{
          $t("customer_trip_order")
        }}</span>
              </a>
            </li>

            <li class="nav-item" v-if="permission.PODController?.method_custom5
          ">
              <a href="/ct-orders/pod" class="nav-link">
                <i class="link-icon mdi mdi-pandora"></i>
                <span class="link-title">POD</span>
              </a>
            </li>

            <li class="nav-item" v-if="permission.RequestRgController?.method_custom5
          ">
              <a href="/request-rg" class="nav-link">
                <i class="link-icon mdi mdi-email-search-outline"></i>
                <span class="link-title">Request Rg</span>
              </a>
            </li>


           
            <li class="nav-item" v-if="user_type == 'CT' &&
          permission.TruckUtilityDetailController?.method_custom1
          ">
              <a href="/absensirecap" class="nav-link">
                <i class="link-icon mdi mdi-alarm"></i>
                <span class="link-title">{{
          $t("driver_attendance_txt")
        }}</span>
              </a>
            </li>
            <li class="nav-item" v-if="user_type == 'CT' &&
          permission.InformasiBongkarController?.method_custom5
          ">
              <a href="/informasiBongkar" class="nav-link">
                <i class="link-icon mdi mdi-package-variant"></i>
                <span class="link-title">Unloading Information</span>
              </a>
            </li>
            <li class="nav-item" v-if="permission.DriverTvController?.method_custom5
          ">
              <a href="/traffict-violation" class="nav-link">
                <i class="link-icon mdi mdi-traffic-light"></i>
                <span class="link-title">{{ $t("trafficViolation") }}</span>
              </a>
            </li>
            <li class="nav-item" v-if="permission.DriverAccidentController?.method_custom5
          ">
              <a href="/accident" class="nav-link">
                <i class="link-icon mdi mdi-truck"></i>
                <span class="link-title">{{ $t("accident") }}</span>
              </a>
            </li>

            <li class="nav-item" v-if="permission.DriverPOKController?.method_custom5
          ">
              <a href="/pok" class="nav-link">
                <i class="link-icon mdi mdi-account"></i>
                <span class="link-title">POK</span>
              </a>
            </li>
            <li class="nav-item" v-if="permission.CriminalityController?.method_custom5
          ">
              <a href="/kriminalitas" class="nav-link">
                <i class="link-icon mdi mdi-police-station"></i>
                <span class="link-title">{{ $t('criminality') }}</span>
              </a>
            </li>
            <template v-if="company_code == 'LINC' || company_code == 'BP'">
              <li class="nav-item" v-if="user_type == 'CT'">
                <a href="/edit-do-number" class="nav-link">
                  <i class="link-icon mdi mdi-shopping"></i>
                  <span class="link-title">Edit Do Number</span>
                </a>
              </li>
            </template>

            <li class="nav-item nav-category" v-if="permission.PerformanceInvoiceController
          ">
              Proforma Invoice
            </li>
            <li class="nav-item" v-if="permission.PerformanceInvoiceController?.method_custom5
          ">
              <a href="/proforma-invoice" class="nav-link">
                <i class="link-icon mdi mdi-chart-line"></i>
                <span class="link-title">Dashboard</span>
              </a>
            </li>
            <li class="nav-item" v-if="permission.PerformanceInvoiceController?.method_custom5
          ">
              <a href="/proforma-invoice/created" class="nav-link">
                <i class="link-icon mdi mdi-plus"></i>
                <span class="link-title">Create Proforma Invoice</span>
              </a>
            </li>
            <li class="nav-item" v-if="user_type == 'CT' &&
          permission.PerformanceInvoiceController?.method_custom5
          ">
              <a href="/proforma-invoice/post" class="nav-link">
                <i class="link-icon mdi mdi-pin"></i>
                <span class="link-title">Posting Proforma Invoice</span>
              </a>
            </li>
            <li class="nav-item" v-if="user_type == 'CT' &&
          permission.PerformanceInvoiceController?.method_custom5
          ">
              <a href="/proforma-invoice/print" class="nav-link">
                <i class="link-icon mdi mdi-printer"></i>
                <span class="link-title">Printed Proforma Invoice</span>
              </a>
            </li>

            <!-- <li
              class="nav-item nav-category"
              v-if="
                user_type == 'CT'"
            >
              <i class="mdi mdi-clipboard-text"></i>
              Logs
            </li>
            
            <li
              class="nav-item"
              v-if="
                user_type == 'CT'"
            >
              <a href="/monstrans-log" class="nav-link">
                <i class="fas fa-list"></i>
                <span class="link-title">Monstrans Log</span>
              </a>
            </li> -->



            <li class="nav-item nav-category" v-if="permission.GateTransactionController
          ">
              <i class="mdi mdi-table"></i> Gate System
            </li>




            <!-- Gate System -->

            <span v-if="user_type == 'CT' &&
          permission.GateTransactionController?.method_custom5
          ">
              <li class="nav-item">
                <a href="/dashboard-gt" class="nav-link">
                  <i class="link-icon" data-feather="box"></i>
                  <span class="link-title">{{ $t("dashboardMenu") }} Gate</span>
                </a>
              </li>

              <span v-for="branch in branchDatas" :key="branch.branch_id">
                <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="collapse" :href="'#branch' + branch.branch_id" role="button"
                    aria-expanded="false" aria-controls="emails">
                    <i class="mdi mdi-home-map-marker"></i>

                    <span class="link-title">{{ branch.branch_name }}</span>

                    <i class="link-arrow mdi mdi-arrow-down-drop-circle"></i>
                  </a>
                  <div class="collapse" :id="'branch' + branch.branch_id">
                    <ul class="nav sub-menu">
                      <li class="nav-item">
                        <a :href="'/gt/branch-dashboard/' +
          $onRandom(branch.branch_id)
          " class="nav-link">{{ $t("all") }}</a>
                      </li>
                      <span v-for="pool in branch.pools" :key="pool.master_pool_id">
                        <li class="nav-item">
                          <a :href="'/gt/pool-dashboard/' +
          $onRandom(pool.master_pool_id)
          " class="nav-link">{{ pool.pool_name }}</a>
                        </li>
                      </span>
                    </ul>
                  </div>
                </li>
              </span>
            </span>

            <!-- <span v-if="role=='root_gt'"> -->

            <!--li class="nav-item nav-category">{{ $t("AdministrationTxt") }}</li-->

            <li class="nav-item" v-if="permission.NewsPortalController?.method_custom5">
              <a href="/gt/news-portal" class="nav-link">
                <i class="link-icon" data-feather="layout"></i>
                <span class="link-title">{{ $t("news_portal_txt") }}</span>
              </a>
            </li>
            <li class="nav-item" v-if="permission.UserGateSystemController?.method_custom5">
              <a href="/gt/user-mapping" class="nav-link">
                <i class="link-icon" data-feather="user"></i>
                <span class="link-title">{{ $t("txtUserMapping") }}</span>
              </a>
            </li>


            <!-- </span> -->

            <!-- //Gate System -->

            <li class="nav-item nav-category" v-if="company_code == 'LINC' || company_code == 'BP'">
              <i class="mdi mdi-clipboard-text"></i> Report
            </li>

            <li class="nav-item" v-if="permission.AlertController?.method_custom5">
              <a href="/alertTurnOff" class="nav-link">
                <i class="link-icon mdi mdi-bell-ring"></i>
                <span class="link-title">Turn Off Alert</span>
              </a>
            </li>

            <li class="nav-item nav-category" v-if="company_code == 'LINC' || company_code == 'BP'">
              <i class="mdi mdi-cog"></i> Setting
            </li>

            <li class="nav-item" v-if="permission.UserController?.method_custom5
          ">
              <a href="/users" class="nav-link">
                <i class="link-icon mdi mdi-account"></i>
                <span class="link-title">User</span>
              </a>
            </li>

            <li class="nav-item" v-if="permission.BranchController?.method_custom5
          ">
              <a href="/branch" class="nav-link">
                <i class="link-icon mdi mdi-city-variant"></i>
                <span class="link-title">Branch</span>
              </a>
            </li>

            <li class="nav-item" v-if="permission.MasterTruckController?.method_custom5
          ">
              <a href="/truck" class="nav-link">
                <i class="link-icon mdi mdi-truck"></i>
                <span class="link-title">Truck & GPS</span>
              </a>
            </li>
            <li class="nav-item" v-if="permission.MasterCustomerController?.method_custom5
          ">
              <a href="/master-customer-ct" class="nav-link">
                <i class="link-icon mdi mdi-account-switch"></i>
                <span class="link-title">Customer</span>
              </a>
            </li>

            <li class="nav-item" v-if="permission.MasterDriverController?.method_custom5
          ">
              <a href="/driver" class="nav-link">
                <i class="link-icon mdi mdi-account"></i>
                <span class="link-title">Driver</span>
              </a>
            </li>


            <li class="nav-item" v-if="permission.DriverApprovalController?.method_custom5
          ">
              <a href="/driver/driver-approval" class="nav-link">
                <i class="link-icon mdi mdi-account-check"></i>
                <span class="link-title">Driver Blacklist Approval</span>
              </a>
            </li>


            <li class="nav-item" v-if="permission.DriverBlacklistController?.method_custom5
          ">
              <a href="/driver/driver-blacklist" class="nav-link">
                <i class="link-icon mdi mdi-account-cancel"></i>
                <span class="link-title">Driver Blacklist</span>
              </a>
            </li>

            <li class="nav-item" v-if="permission.MasterStoplocationController?.method_custom5
          ">
              <a href="/stop-location" class="nav-link">
                <i class="link-icon mdi mdi-bus-stop"></i>
                <span class="link-title">Stop Location</span>
              </a>
            </li>
            <li class="nav-item" v-if="permission.MasterLeadtimeController?.method_custom5
          ">
              <a href="/lead-time" class="nav-link">
                <i class="link-icon mdi mdi-clock"></i>
                <span class="link-title">Leadtime</span>
              </a>
            </li>

            <li class="nav-item" v-if="permission.TypeDocumentController?.method_custom5 == 1
          ">
              <a href="/type-document" class="nav-link">
                <i class="link-icon mdi mdi-file-document"></i>
                <span class="link-title">Type Document</span>
              </a>
            </li>

            <li class="nav-item" v-if="permission.MasterDocumentController?.method_custom5
          ">
              <a href="/dok" class="nav-link">
                <i class="link-icon mdi mdi-file-document"></i>
                <span class="link-title">Document</span>
              </a>
            </li>

            <li class="nav-item" v-if="permission.MasterCarrierController?.method_custom5
          ">
              <a href="/master-carrier" class="nav-link">
                <i class="link-icon mdi mdi-file-document"></i>
                <span class="link-title">Carrier</span>
              </a>
            </li>

            <li class="nav-item" v-if="permission.permissionMasterStatusController?.method_custom5
          ">
              <a href="/status" class="nav-link">
                <i class="link-icon mdi mdi-file-document"></i>
                <span class="link-title">Status</span>
              </a>
            </li>

            <li class="nav-item" v-if="permission.AlertSettingController?.method_custom5
          ">
              <a href="/alert-setting" class="nav-link">
                <i class="link-icon mdi mdi-alert-circle"></i>
                <span class="link-title">Alert</span>
              </a>
            </li>

          </span>




          <span v-if="user_type == 'GT'">
            <!-- <span
              v-for="permision in permision_role"
              :key="permision.permission_role_id"
            > -->
            <li class="nav-item nav-category" v-if="permission.GateTransactionController?.method_custom5
          ">
              <i class="mdi mdi-table"></i> Gate System
            </li>

            <!-- Gate System -->

            <span v-if="permission.GateTransactionController?.method_custom5
          ">
              <li class="nav-item">
                <a href="/dashboard-gt" class="nav-link">
                  <i class="link-icon" data-feather="box"></i>
                  <span class="link-title">{{ $t("dashboardMenu") }} Gate</span>
                </a>
              </li>

              <span v-for="branch in branchDatas" :key="branch.branch_id">
                <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="collapse" :href="'#branch' + branch.branch_id" role="button"
                    aria-expanded="false" aria-controls="emails">
                    <i class="mdi mdi-home-map-marker"></i>

                    <span class="link-title">{{ branch.branch_name }}</span>

                    <i class="link-arrow mdi mdi-arrow-down-drop-circle"></i>
                  </a>
                  <div class="collapse" :id="'branch' + branch.branch_id">
                    <ul class="nav sub-menu">
                      <li class="nav-item">
                        <a :href="'/gt/branch-dashboard/' +
          $onRandom(branch.branch_id)
          " class="nav-link">{{ $t("all") }}</a>
                      </li>
                      <span v-for="pool in branch.pools" :key="pool.master_pool_id">
                        <li class="nav-item">
                          <a :href="'/gt/pool-dashboard/' +
          $onRandom(pool.master_pool_id)
          " class="nav-link">{{ pool.pool_name }}</a>
                        </li>
                      </span>
                    </ul>
                  </div>
                </li>
              </span>
            </span>

            <span v-if="permission.NewsPortalController?.method_custom5">
              <li class="nav-item">
                <a href="/gt/news-portal" class="nav-link">
                  <i class="link-icon" data-feather="layout"></i>
                  <span class="link-title">{{ $t("news_portal_txt") }}</span>
                </a>
              </li>
            </span>

            <span v-if="permission.UserGateSystemController?.method_custom5
          ">
              <li class="nav-item">
                <a href="/gt/user-mapping" class="nav-link">
                  <i class="link-icon" data-feather="user"></i>
                  <span class="link-title">{{ $t("txtUserMapping") }}</span>
                </a>
              </li>
            </span>
            <!-- </span> -->
          </span>

          <!-- Faktur Pajak -->

          <span v-if="user_type == 'FP'">
            <li class="nav-item">
              <a href="/dashboard-fp" class="nav-link">
                <i class="link-icon" data-feather="box"></i>
                <span class="link-title">{{ $t("dashboardMenu") }} </span>
              </a>
            </li>

            <span v-if="role == 'user_faktur_pajak'">
              <li class="nav-item nav-category">
                <i class="mdi mdi-cog"></i> Menu
              </li>

              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="collapse" href="#orders" role="button" aria-expanded="false"
                  aria-controls="emails">
                  <i class="link-icon mdi mdi-file-document"></i>
                  <span class="link-title">Tax Invoice</span>
                  <i class="link-arrow mdi mdi-arrow-down-drop-circle"></i>
                </a>
                <div class="collapse" id="orders">
                  <ul class="nav sub-menu">
                    <span v-for="cFP in companyFP" :key="cFP.company_id">
                      <li class="nav-item">
                        <a :href="'/faktur-pajak/bycompany/' + cFP.code" class="nav-link">{{ cFP.name }}</a>
                      </li>
                    </span>
                  </ul>
                </div>
              </li>
            </span>
            <span v-if="role == 'root_faktur_pajak'">
              <li class="nav-item nav-category">
                <i class="mdi mdi-cog"></i> Menu
              </li>

              <li class="nav-item">
                <a href="/faktur-pajak" class="nav-link">
                  <i class="link-icon mdi mdi-file-document"></i>
                  <span class="link-title">Tax Invoice</span>
                </a>
              </li>

              <li class="nav-item nav-category">
                {{ $t("AdministrationTxt") }}
              </li>
              <span v-for="permision in permision_role" :key="permision.permission_role_id">
                <span v-if="permision.permission.controller == 'VendorFPController'">
                  <li class="nav-item">
                    <a href="/faktur-pajak/vendor" class="nav-link">
                      <i class="link-icon" data-feather="trello"></i>
                      <span class="link-title">{{ $t("vendor") }}</span>
                    </a>
                  </li>
                </span>

                <span v-if="permision.permission.controller == 'UserFpController'">
                  <li class="nav-item">
                    <a href="/faktur-pajak/user-access" class="nav-link">
                      <i class="link-icon" data-feather="trello"></i>
                      <span class="link-title">{{ $t("userAccess") }}</span>
                    </a>
                  </li>
                </span>
              </span>
            </span>
          </span>

          <!-- //Faktur Pajak -->

          <li class="nav-item nav-category" v-if="user_type == 'CHAIN'">
            {{ $t("transactionData") }}
          </li>


          <li class="nav-item" v-if="user_type == 'CHAIN'">
            <a class="nav-link" data-bs-toggle="collapse" href="#orders" role="button" aria-expanded="false"
              aria-controls="emails">
              <i class="link-icon" data-feather="shopping-bag"></i>
              <span class="link-title">Order</span>
              <i class="link-arrow mdi mdi-arrow-down-drop-circle"></i>
            </a>
            <div class="collapse" id="orders">
              <ul class="nav sub-menu">
                <li class="nav-item" v-if="permission.TransportOrderTemplateController?.method_custom5
          ">
                  <a href="/order/transport-template" class="nav-link">Template Order</a>
                </li>
                <li class="nav-item" v-if="permission.TransportOrderHeaderController?.method_custom5
          ">
                  <a href="/order/transport-order" class="nav-link">Transport Order</a>
                </li>

                <li class="nav-item" v-if="permission.TransportOrderHeaderNewController?.method_custom5
          ">
                  <a href="/order/new-transport-order" class="nav-link">New Transport Order</a>
                </li>

                <li class="nav-item" v-if="permission.TransportOrderHeaderNewController?.method_custom5
          ">
                  <a href="/order/new-transport-order-multi" class="nav-link">New Transport Order Multi</a>
                </li>

                <li class="nav-item" v-if="permission.TransportOrderTemplateController?.method_custom5
          ">
                  <a href="/order/transport-template-multi" class="nav-link">Template Order Multi</a>
                </li>

                <li class="nav-item" v-if="permission.TransportOrderHeaderController?.method_custom5
          ">
                  <a href="/order/transport-order-multi" class="nav-link">Transport Order Multi</a>
                </li>
              </ul>
            </div>
          </li>

          <li class="nav-item nav-category" v-if="(user_type == 'CHAIN' || user_type == 'CT') &&
          permission.RateManagementController?.method_custom5
          ">
            Rate Management
          </li>

          <li class="nav-item" v-if="user_type == 'CHAIN' &&
          permission.RmBillableRateHeaderController?.method_custom5
          ">
            <a href="/billable-rate" class="nav-link">
              <i class="link-icon mdi mdi-cash"></i>
              <span class="link-title">Billable Rate </span>
            </a>
          </li>



          <li class="nav-item" v-if="user_type == 'CHAIN' &&
          permission.RmBillableTariffController?.method_custom5
          ">
            <a href="/billable-tariff" class="nav-link">
              <i class="link-icon mdi mdi-cash"></i>
              <span class="link-title">Billable Tariff </span>
            </a>
          </li>
          <li class="nav-item" v-if="user_type == 'CHAIN' &&
          permission.RmBillableMethodController?.method_custom5
          ">
            <a href="/billable-method" class="nav-link">
              <i class="link-icon mdi mdi-cash"></i>
              <span class="link-title">Billable Method </span>
            </a>
          </li>


          <li class="nav-item" v-if="user_type == 'CT' &&
          permission.RmPayableTariffController?.method_custom5
          ">
            <a href="/payable-tariff" class="nav-link">
              <i class="link-icon mdi mdi-cash"></i>
              <span class="link-title">Payable Tariff </span>
            </a>
          </li>

          <li class="nav-item" v-if="user_type == 'CHAIN' &&
          permission.RmBillableRateSfController?.method_custom5
          ">
            <a href="/new-rate " class="nav-link">
              <i class="link-icon mdi mdi-monitor-multiple"></i>
              <span class="link-title">Rate Integration Monitor</span>
            </a>
          </li>

          <li class="nav-item" v-if="user_type == 'CHAIN' &&
          permission.RmFreightBillablePartiesController?.method_custom5
          ">
            <a href="/billable-parties" class="nav-link">
              <i class="link-icon mdi mdi-truck"></i>
              <span class="link-title">Freight Billable Parties</span>
            </a>
          </li>
          <li class="nav-item" v-if="user_type == 'CHAIN' &&
          permission.SalesProjectController?.method_custom5
          ">
            <a href="/sales-project" class="nav-link">
              <i class="link-icon mdi mdi-cash"></i>
              <span class="link-title">Sales Project Kickoff </span>
            </a>
          </li>
          <li class="nav-item" v-if="user_type == 'CHAIN' &&
          permission.RmBillableAccRuleController?.method_custom5
          ">
            <a href="/billable-acc-rule" class="nav-link">
              <i class="link-icon mdi mdi-file-percent"></i>
              <span class="link-title">Billable Acc Rule </span>
            </a>
          </li>
          <li class="nav-item" v-if="user_type == 'CT' &&
          permission.MasterLookupController?.method_custom5
          ">
            <a href="/lookup-code" class="nav-link">
              <i class="link-icon mdi mdi-gate"></i>
              <span class="link-title">Master Lookup Code </span>
            </a>
          </li>

          <li class="nav-item" v-if="user_type == 'CT' &&
          permission.RmTollGateMasterController?.method_custom5
          ">
            <a href="/gerbang-tol" class="nav-link">
              <i class="link-icon mdi mdi-gate"></i>
              <span class="link-title">Master Gerbang Tol </span>
            </a>
          </li>

          <li class="nav-item" v-if="user_type == 'CT' &&
          permission.RmTollRouteController?.method_custom5
          ">
            <a href="/tol-route" class="nav-link">
              <i class="link-icon mdi mdi-car"></i>
              <span class="link-title">Master Rute Tol </span>
            </a>
          </li>


          <li class="nav-item" v-if="user_type == 'CT' &&
          permission.RmRouteMasterController?.method_custom5
          ">
            <a class="nav-link collapsed" data-bs-toggle="collapse" href="#routeMenu" role="button"
              aria-expanded="false" aria-controls="payableRateMenu">
              <i class="link-icon mdi mdi-routes"></i>
              <span class="link-title">Rute</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-chevron-down link-arrow">
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            </a>
            <div class="collapse" id="routeMenu" style="">
              <ul class="nav sub-menu">
                <!-- <li class="nav-item">
                  <a href="/rute?params=request" class="nav-link">Request Rute</a>
                </li> -->
                <li class="nav-item">
                  <a href="/rute?params=request" v-if="permission.RmRouteMasterController?.method_custom1"
                    class="nav-link">Request Rute</a>
                </li>

                <li class="nav-item">
                  <a href="/rute" class="nav-link" v-if="permission.RmRouteMasterController?.method_custom2">Master
                    Rute</a>
                </li>

              </ul>
            </div>
          </li>

          <!-- <li
              class="nav-item"
              v-if="
                user_type == 'CT' &&
                permission.RmRouteMasterController?.method_custom5
              "
            >
              <a href="/rute" class="nav-link">
                <i class="link-icon mdi mdi-routes"></i>
                <span class="link-title">Master Rute </span>
              </a>
            </li> -->

          <li class="nav-item" v-if="user_type == 'CT' &&
          permission.RmFuelMasterController?.method_custom5
          ">
            <a href="/master-bbm" class="nav-link">
              <i class="link-icon mdi mdi-gas-station"></i>
              <span class="link-title">Master BBM </span>
            </a>
          </li>

          <li class="nav-item" v-if="user_type == 'CT' &&
          permission.RmMelMasterController?.method_custom5
          ">
            <a href="/master-mel" class="nav-link">
              <i class="link-icon mdi mdi-gas-station"></i>
              <span class="link-title">Master MEL </span>
            </a>
          </li>

          <li class="nav-item" v-if="user_type == 'CT' &&
          permission.RmRatioMasterController?.method_custom5
          ">
            <a href="/master-rasio" class="nav-link">
              <i class="link-icon mdi mdi-folder"></i>
              <span class="link-title">Master Rasio </span>
            </a>
          </li>

          <li class="nav-item" v-if="user_type == 'CT' &&
          permission.RmUjoMasterController?.method_custom5
          ">
            <a class="nav-link collapsed" data-bs-toggle="collapse" href="#ujoMenu" role="button" aria-expanded="false"
              aria-controls="payableRateMenu">
              <i class="link-icon mdi mdi-cash-multiple"></i>
              <span class="link-title">UJO</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-chevron-down link-arrow">
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            </a>
            <div class="collapse" id="ujoMenu" style="">
              <ul class="nav sub-menu">
                <li class="nav-item">
                  <!-- <a href="/payable-rate/rute" class="nav-link">By Rute</a> -->
                  <a href="/master-ujo?params=request" v-if="permission.RmUjoMasterController?.method_custom1"
                    class="nav-link">Request Ujo</a>
                </li>
                <li class="nav-item">
                  <a href="/master-ujo?params=approval" v-if="permission.RmUjoMasterController?.method_custom2"
                    class="nav-link">Approval Ujo</a>
                </li>

                <li class="nav-item">
                  <a href="/master-ujo" class="nav-link" v-if="permission.RmUjoMasterController?.method_custom3">Master
                    Ujo</a>
                </li>

              </ul>
            </div>
          </li>

          <!-- <li
              class="nav-item"
              v-if="
                user_type == 'CT' &&
                permission.RmUjoMasterController?.method_custom5
              "
            >
              <a href="/master-ujo" class="nav-link">
                <i class="link-icon mdi mdi-cash"></i>
                <span class="link-title">Master Ujo </span>
              </a>
            </li> -->
          <li class="nav-item" v-if="user_type == 'CT' &&
          permission.RateManagementController?.method_custom5
          ">
            <a href="#" class="nav-link">
              <i class="link-icon mdi mdi-currency-usd"></i>
              <span class="link-title">Index Customer UJO </span>
            </a>
          </li>
          <li class="nav-item" v-if="user_type == 'CT' &&
          permission.RateManagementController?.method_custom5
          ">
            <a href="#" class="nav-link">
              <i class="link-icon mdi mdi-map-check"></i>
              <span class="link-title">Request Rute </span>
            </a>
          </li>
          <li class="nav-item" v-if="user_type == 'CT' &&
          permission.RateManagementController?.method_custom5
          ">
            <a href="#" class="nav-link">
              <i class="link-icon mdi mdi-cash-multiple"></i>
              <span class="link-title">Verified Ujo </span>
            </a>
          </li>
          <li class="nav-item" v-if="user_type == 'CT' &&
          permission.RmFerryMasterController?.method_custom5
          ">
            <a href="/master-penyebrangan" class="nav-link">
              <i class="link-icon mdi mdi-ferry"></i>
              <span class="link-title">Master Penyebrangan </span>
            </a>
          </li>
          <li class="nav-item" v-if="user_type == 'CT' &&
          permission.RmFerryRuteController?.method_custom5
          ">
            <a href="/master-rute-penyebrangan" class="nav-link">
              <i class="link-icon mdi mdi-ship-wheel"></i>
              <span class="link-title">Master Rute Penyebrangan </span>
            </a>
          </li>
          <li class="nav-item" v-if="user_type == 'CT' &&
          permission.RmChargeCodeController?.method_custom5
          ">
            <a href="/charge-code" class="nav-link">
              <i class="link-icon mdi mdi-cog"></i>
              <span class="link-title">Charge Code </span>
            </a>
          </li>

          <li class="nav-item" v-if="user_type == 'CT' &&
          permission.RmServiceRequestAppController?.method_custom5
          ">
            <a href="/billable-manage-service" class="nav-link">
              <i class="link-icon mdi mdi-cash"></i>
              <span class="link-title">Manage Service Request</span>
            </a>
          </li>

          <li class="nav-item" v-if="user_type == 'CHAIN' && permission.RequestRgController?.method_custom5
          ">
            <a href="/request-rg" class="nav-link">
              <i class="link-icon mdi mdi-email-search-outline"></i>
              <span class="link-title">Request Rg</span>
            </a>
          </li>
          <!-- <span
            v-for="permision in permision_role"
            :key="permision.permission_role_id"
          > -->

          <!-- <li
              class="nav-item"
              v-if="
                user_type == 'CHAIN' &&
                permision.permission.controller == 'RmBillableRateAccController'
              "
            >
              <a href="/billable-rate-acc" class="nav-link">
                <i class="link-icon mdi mdi-cash"></i>
                <span class="link-title">Billable Rate Acc </span>
              </a>
            </li> -->

          <!-- <li
              class="nav-item"
              v-if="
                user_type == 'CHAIN' &&
                permision.permission.controller == 'RmChargeCodeController'
              "
            >
              <a href="/charge-code" class="nav-link">
                <i class="link-icon mdi mdi-cash"></i>
                <span class="link-title">Manage Charge Code</span>
              </a>
            </li> -->
          <!-- 
            <li
              class="nav-item"
              v-if="
                user_type == 'CHAIN' &&
                permision.permission.controller == 'RateManagementController'
              "
            >
              <a href="/billable-manage-service" class="nav-link">
                <i class="link-icon mdi mdi-cash"></i>
                <span class="link-title">Manage Service Request</span>
              </a>
            </li> -->




          <!-- <li class="nav-item" v-if="user_type == 'CT' && permision.permission.controller == 'RateManagementController'">
              <a href="/stop-location-rm " class="nav-link">
                <i class="link-icon mdi mdi-bus-stop"></i>
                <span class="link-title">Stop Location </span>
              </a>
            </li> -->


          <!-- </span> -->

          <li class="nav-item" v-if="user_type == 'CT' ">
            <a class="nav-link collapsed" data-bs-toggle="collapse" href="#mprMenu" role="button" aria-expanded="false"
              aria-controls="payableRateMenu">
              <i class="link-icon mdi mdi-file-settings"></i>
              <span class="link-title">MPR</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-chevron-down link-arrow">
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            </a>
            <div class="collapse" id="mprMenu" style="">
              <ul class="nav sub-menu">

                <li class="nav-item" v-if="permission.MprDashboardController">
                  <a href="/mpr-dashboard"
                    class="nav-link">Dashboard</a>
                </li>

                <li class="nav-item" v-if="permission.MprAccessController">
                  <a href="/mpr-akses"
                    class="nav-link">Akses & Notifikasi</a>
                </li>

                <li class="nav-item" v-if="permission.MprPengajuanController">
                  <!-- <a href="/payable-rate/rute" class="nav-link">By Rute</a> -->
                  <a href="/mpr-pengajuan"
                    class="nav-link">Pengajuan</a>
                </li>
                <li class="nav-item" v-if="permission.MprApprovalController">
                  <a href="/mpr-approval"
                    class="nav-link">Approval</a>
                </li>

                <li class="nav-item" v-if="permission.MprPenerimaanController">
                  <a href="/mpr-penerimaan" class="nav-link" >Penerimaan</a>
                </li>

              </ul>
            </div>
          </li>


          <li class="nav-item" v-if="permission.RmPayableRateHeaderController?.method_custom5">
            <a class="nav-link collapsed" data-bs-toggle="collapse" href="#payableRateMenu" role="button"
              aria-expanded="false" aria-controls="payableRateMenu">
              <i class="link-icon mdi mdi-cash-multiple"></i>
              <span class="link-title">Payable Rate</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-chevron-down link-arrow">
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            </a>
            <div class="collapse" id="payableRateMenu" style="">
              <ul class="nav sub-menu">
                <li class="nav-item">
                  <!-- <a href="/payable-rate/rute" class="nav-link">By Rute</a> -->
                  <a href="/payable-rate/rute" class="nav-link">By Rute</a>
                </li>
                <li class="nav-item">
                  <a href="/payable-rate/customer" class="nav-link">By Customer</a>
                </li>

                <li class="nav-item" v-if="permission.RmPayableRateArchivingController?.method_custom5">
                  <a href="/payable-rate/archiving" class="nav-link">Archiving</a>
                </li>

              </ul>
            </div>
          </li>

          <!-- <li class="nav-item" v-if="permission.RmPayableRateHeaderController?.method_custom5">
              <a href="/payable-rate/archiving" class="nav-link">
                <i class="link-icon mdi mdi-archive"></i>
                <span class="link-title"> Payable Rate Archiving </span>
              </a>
            </li> -->



            
          <li class="nav-item nav-category" v-if="(permission.FppkController?.method_custom5 || permission.EmailNotifFppkController?.method_custom5)
          ">
            FPPK
          </li>


          <li class="nav-item" v-if="permission.PengajuanFppkController?.method_custom5
          ">
            <a href="/fppk-pengajuan" class="nav-link">
              <i class="link-icon mdi mdi-clipboard-arrow-right"></i>
              <span class="link-title">{{ this.$t("fppkMenuPengajuan") }} </span>
            </a>
          </li>
          <li class="nav-item" v-if="permission.PenerimaanFppkController?.method_custom5
          ">
            <a href="/fppk-penerimaan" class="nav-link">
              <i class="link-icon mdi mdi-clipboard-arrow-left"></i>
              <span class="link-title">{{ this.$t("fppkMenuPenerimaan") }} </span>
            </a>
          </li>
          <li class="nav-item" v-if="permission.EmailNotifFppkController?.method_custom5
          ">
            <a href="/fppk-notification" class="nav-link">
              <i class="link-icon mdi mdi-bell-ring"></i>
              <span class="link-title">Email Notification </span>
            </a>
          </li>

          <li class="nav-item" v-if="permission.ReportFppkController?.method_custom5
          ">
            <a href="/dashboard-fppk" class="nav-link">
              <i class="link-icon mdi mdi-chart-line"></i>
              <span class="link-title">Report</span>
            </a>

          </li>
          <br>
          <li class="nav-item nav-category" v-if="user_type == 'CT'">
            Load Request
          </li>
          <li class="nav-item" v-if="user_type == 'CT' &&
          permission.MobileShipmentController?.method_custom5
          ">
            <a href="/request-muat" class="nav-link">
              <i class="link-icon mdi mdi-package-up"></i>
              <span class="link-title">Request Muat </span>
              <span class="badge rounded-pill bg-primary" style="margin-left: 20px">{{ totalReqMuat }}</span>

            </a>
          </li>
          <li class="nav-item" v-if="user_type == 'CT' &&
          permission.MobileShipmentController?.method_custom5
          ">
            <a href="/request-bongkar" class="nav-link">
              <i class="link-icon mdi mdi-package-down"></i>
              <span class="link-title">Request Bongkar </span>
              <span class="badge rounded-pill bg-primary" style="margin-left: 20px">{{ totalReqBongkar }}</span>
            </a>
          </li>
          <li class="nav-item" v-if="user_type == 'CT' &&
          permission.MobileShipmentController?.method_custom5
          ">
            <a href="/request-leadtime" disabled class="nav-link">
              <i class="link-icon mdi mdi-speedometer"></i>
              <span class="link-title">Request LeadTime </span>
              <span class="badge rounded-pill bg-primary" style="margin-left: 20px">{{ totalReqLeadtime }}</span>
            </a>
          </li>
          <li class="nav-item" v-if="user_type == 'CT' &&
          permission.MobileShipmentController?.method_custom5
          ">
            <a href="/request-pod" disabled class="nav-link">
              <i class="link-icon mdi mdi-text-box-check"></i>
              <span class="link-title">Request e-POD </span>
              <span class="badge rounded-pill bg-primary" style="margin-left: 20px">{{ totalReqEPod }}</span>
            </a>
          </li>

          <li class="nav-item" v-if="user_type == 'CT' &&
          permission.MobileShipmentController?.method_custom5
          ">
            <a href="/request-ba" disabled class="nav-link">
              <i class="link-icon mdi mdi-newspaper-check"></i>
              <span class="link-title">Request BA </span>
              <span class="badge rounded-pill bg-primary" style="margin-left: 20px">{{ totalReqBa }}</span>
            </a>
          </li>
          <li class="nav-item" v-if="user_type == 'CT' &&
          permission.MobileShipmentController?.method_custom5
          ">
            <a href="/request-leave" disabled class="nav-link">
              <i class="link-icon mdi mdi-calendar-end"></i>
              <span class="link-title">Request for Leave </span>
              <span class="badge rounded-pill bg-primary" style="margin-left: 20px">{{ totalReqLeave }}</span>
            </a>
          </li>
          <br />
          <span>
            <li class="nav-item nav-category" v-if="user_type == 'CHAIN'">
              {{ $t("masterData") }}
            </li>
            <li class="nav-item" v-if="permission.RegionController?.method_custom5">
              <a href="/region" class="nav-link">
                <i class="link-icon" data-feather="bookmark"></i>
                <span class="link-title">{{ $t("regionTxt") }}</span>
              </a>
            </li>
            <li class="nav-item" v-if="permission.PackageController?.method_custom5">
              <a href="/package" class="nav-link">
                <i class="link-icon" data-feather="package"></i>
                <span class="link-title">{{ $t("packageTxt") }}</span>
              </a>
            </li>
            <li class="nav-item" v-if="permission.PostalCodeController?.method_custom5">
              <a href="/postal-code" class="nav-link">
                <i class="link-icon" data-feather="book"></i>
                <span class="link-title">{{ $t("postalCodeTxt") }}</span>
              </a>
            </li>
            <li class="nav-item" v-if="permission.CommodityController?.method_custom5">
              <a href="/commodity" class="nav-link">
                <i class="link-icon" data-feather="aperture"></i>
                <span class="link-title">{{ $t("commodityTxt") }}</span>
              </a>
            </li>
            <li class="nav-item" v-if="permission.SystemCodeController?.method_custom5">
              <a href="/system-code" class="nav-link">
                <i class="link-icon" data-feather="code"></i>
                <span class="link-title">{{ $t("systemCodeTxt") }}</span>
              </a>
            </li>
            <li class="nav-item" v-if="permission.SkuController?.method_custom5">
              <a href="/sku" class="nav-link">
                <i class="link-icon" data-feather="archive"></i>
                <span class="link-title">SKU</span>
              </a>
            </li>
            <li class="nav-item" v-if="permission.LocationController?.method_custom5">
              <a href="/location" class="nav-link">
                <i class="link-icon" data-feather="map"></i>
                <span class="link-title">{{ $t("locationTxt") }}</span>
              </a>
            </li>
            <!-- <span
              v-for="permision in permision_role"
              :key="permision.permission_role_id"
            >
              <span
                v-if="
                  permision.permission.controller == 'RegionController' &&
                  permision.method_custom5 == 1
                "
              >
               
              </span>

              <span
                v-if="
                  permision.permission.controller == 'PackageController' &&
                  permision.method_custom5 == 1
                "
              >
              </span>

              <span
                v-if="
                  permision.permission.controller == 'PostalCodeController' &&
                  permision.method_custom5 == 1
                "
              >
              </span>

              <span
                v-if="
                  permision.permission.controller == 'CommodityController' &&
                  permision.method_custom5 == 1
                "
              >
              </span>

              <span
                v-if="
                  permision.permission.controller == 'SystemCodeController' &&
                  permision.method_custom5 == 1
                "
              >
              </span>

              <span
                v-if="
                  permision.permission.controller == 'SkuController' &&
                  permision.method_custom5 == 1
                "
              >
              </span>

              <span
                v-if="
                  permision.permission.controller == 'LocationController' &&
                  permision.method_custom5 == 1
                "
              >
              </span>
            </span> -->
          </span>
          <br />
          <span v-if="this.role == 'superadmin'">
            <li class="nav-item nav-category">{{ $t("AdministrationTxt") }}</li>
            <li class="nav-item" v-if="permission.UserController?.method_custom5">
              <a href="/users" class="nav-link">
                <i class="link-icon" data-feather="user"></i>
                <span class="link-title">{{ $t("txtUsers") }}</span>
              </a>
            </li>
            <li class="nav-item" v-if="permission.RoleController?.method_custom5">
              <a href="/role" class="nav-link">
                <i class="link-icon" data-feather="settings"></i>
                <span class="link-title">{{ $t("txtRoles") }}</span>
              </a>
            </li>
            <li class="nav-item" v-if="permission.PermissionController?.method_custom5">
              <a href="/permission" class="nav-link">
                <i class="link-icon" data-feather="message-square"></i>
                <span class="link-title">{{ $t("txtPermission") }}</span>
              </a>
            </li>
            <li class="nav-item" v-if="permission.PermissionRoleController?.method_custom5">
              <a href="/rolpermis" class="nav-link">
                <i class="link-icon" data-feather="lock"></i>
                <span class="link-title">{{
          $t("txtPermissionRole")
        }}</span>
              </a>
            </li>
            <!-- <span
              v-for="permision in permision_role"
              :key="permision.permission_role_id"
            >
              <span
                v-if="permision.permission.controller == 'CompanyController'"
              >
               
              </span>

              <span v-if="permision.permission.controller == 'UserController'">
              </span>

              <span v-if="permision.permission.controller == 'RoleController'">
              </span>

              <span
                v-if="permision.permission.controller == 'PermissionController'"
              >
                
              </span>

              <span
                v-if="
                  permision.permission.controller == 'PermissionRoleController'
                "
              >
               
              </span>
            </span> -->
          </span>
          <li class="nav-item" v-if="permission.CompanyController?.method_custom5 && user_type == 'CHAIN'">
            <a href="/company" class="nav-link">
              <i class="link-icon" data-feather="trello"></i>
              <span class="link-title">{{ $t("companyTxt") }}</span>
            </a>
          </li>

          <li class="nav-item" v-if="permission.MasterCarrierController?.method_custom5 && user_type == 'CHAIN'
          ">
            <a href="/master-carrier" class="nav-link">
              <i class="link-icon mdi mdi-file-document"></i>
              <span class="link-title">Carrier</span>
            </a>
          </li>


        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "sidebarMenu",
  props: {
    // classMenu: {
    //   type: String,
    //   required: true,
    // },
  },
  components: {},
  data() {
    return {
      locale: "",
      name: "",
      email: "",
      role: "",
      permision_role: [],
      branchDatas: [],
      permission: [],
      totalTrip: 0,
      totalDoc: 0,
      totalSA: 0,
      totalGate: 0,
      user_type: "",
      company_code: '',
      companyFP: [],
      totalReqMuat:0,
      totalReqBongkar:0,
      totalReqEPod:0,
      totalReqBa:0,
      totalReqLeave:0,
      totalReqLeadtime:0,
    };
  },
  watch: {},
  methods: {
    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.name = userDatas.sub.name;
      this.email = userDatas.sub.email;
      this.role = userDatas.sub.role.name;
      this.user_type = userDatas.sub.user_type;
      this.company_code = userDatas.sub.company_detail.code;
      this.permision_role = userDatas.sub.permision_role;
      if (this.role == "user_faktur_pajak") {
        this.loadCompanyFP();
      }
      let permis = {};
      userDatas.sub.permision_role.forEach(function (arr) {
        permis[arr.permission.controller] = arr;
      });
      this.permission = permis;
      // console.log(this.permission)
    },

    loadCompanyFP() {
      const baseURI =
        this.$settings.endPoint + `company/company-group-faktur-pajak`;
      return this.$http.get(baseURI).then((response) => {
        this.companyFP = response.data.datas;
      });
    },

    loadTotalAll() {
      if (this.user_type == "CT") {
        const baseURI =
          this.$settings.endPointCt + `real-monitoring/notif-count`;
        return this.$http.get(baseURI).then((response) => {
          this.totalTrip = response.data.datas.notifTrip;
          this.totalDoc = response.data.datas.notifDoc;
          this.totalSA = response.data.datas.notifService;
          this.totalGate = response.data.datas.notifGate;
        });
      }
    },

    getBranch() {
      if (this.user_type == "CT" || this.user_type == "GT") {
        const baseURI =
          this.$settings.endPointCt + `branch/select-all-branch-with-pool`;
        return this.$http.get(baseURI).then((response) => {
          this.branchDatas = response.data.datas;
        });
      }
    },

    loadMobileRequest(){

      var checkMobile = false;
      for(const [key] of Object.entries(this.permission)){
        if(key == "MobileShipmentController"){
          checkMobile = true;
        }
      }
      if(checkMobile){
        const baseURI =
            this.$settings.endPointLincMobile + `mobile/shipment/notif`;
          return this.$http.get(baseURI).then((response) => {
            let res = response.data.datas;
            this.totalReqMuat    = res.muat;
            this.totalReqBongkar = res.bongkar;
            this.totalReqEPod    = res.epod;
            this.totalReqBa      = res.ba;
            this.totalReqLeave   = res.leave;
            this.totalReqLeadtime   = res.leadtime;
          });
      }
    }


    // loadTotalDoc(){
    //    if(this.user_type=='CT'){
    //     const baseURI = this.$settings.endPointCt + `real-monitoring/doc-alert`;
    //     return this.$http.get(baseURI).then((response) => {
    //       this.totalDoc = response.data.datas.total;
    //     });
    //   }
    // },

    // loadTotalSA(){
    //   if(this.user_type=='CT'){
    //     const baseURI = this.$settings.endPointCt + `real-monitoring/service-alert`;
    //     return this.$http.get(baseURI).then((response) => {
    //       this.totalSA = response.data.datas.total;
    //     });
    //   }
    // }
  },
  events: {},
  created: function () { },
  mounted() {
    this.fetchIt();
    this.loadTotalAll();
    this.getBranch();
    this.loadMobileRequest();
    // this.loadTotalDoc();
    // this.loadTotalSA();
    // setInterval(() => {
    //    this.loadTotalTrip();
    // }, 500000);
  },
};
</script>
<style scoped></style>
