var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page-content"},[_c('div',{staticClass:"col-lg-12 col-xl-12 stretch-card"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('h4',{staticClass:"float-start",staticStyle:{"margin-right":"5px"}},[_vm._v(" Akses Dan Notifikasi ")]),(_vm.permision_role.method_create)?_c('button',{staticClass:"btn-success btn btn-sm float-end",attrs:{"type":"button"},on:{"click":_vm.addData}},[_c('i',{staticClass:"link-icon",attrs:{"data-feather":"plus"}}),_vm._v(" Tambah Baru ")]):_vm._e()])])]),_c('div',{staticClass:"card-body"},[_c('vue-good-table',{attrs:{"title":"list-user","mode":"remote","totalRows":_vm.totalRecords,"line-numbers":true,"isLoading":_vm.isLoading,"pagination-options":{
                  enabled: true,
                },"select-options":{
                  enabled: true,
                  selectOnCheckboxOnly: true,
                  selectionText: _vm.$t('data_selected'),
                  clearSelectionText: _vm.$t('clear'),
                },"rows":_vm.rows,"columns":_vm.columns},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'email_notif')?_c('span',[_vm._v(" "+_vm._s(_vm.formatEmailNotifications(props.row.email_notifications))+" ")]):_vm._e(),(props.column.field == 'actions')?_c('span',[_c('a',{attrs:{"href":"#","title":"Edit"},on:{"click":function($event){$event.preventDefault();return _vm.editData(props.index, props.row)}}},[_c('i',{staticClass:"mdi mdi-square-edit-outline",staticStyle:{"font-size":"16px"}})]),_c('a',{attrs:{"href":"#","title":"Delete"},on:{"click":function($event){$event.preventDefault();return _vm.deleteData(props.index, props.row)}}},[_c('i',{staticClass:"mdi mdi-trash-can-outline",staticStyle:{"font-size":"16px"}})])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1),_c('modal-access-mpr',{attrs:{"showModal":_vm.showModal,"type":_vm.type,"data":_vm.data},on:{"closeModal":_vm.closeMyModal}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }