import { render, staticRenderFns } from "./AccidentAdd.vue?vue&type=template&id=5e31aeee&scoped=true"
import script from "./AccidentAdd.vue?vue&type=script&lang=js"
export * from "./AccidentAdd.vue?vue&type=script&lang=js"
import style0 from "./AccidentAdd.vue?vue&type=style&index=0&id=5e31aeee&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e31aeee",
  null
  
)

export default component.exports