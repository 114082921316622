<template>
    <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ type }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')"
              aria-label="btn-close"></button>
          </div>
  
          <form class="forms-sample" method="POST" ref="formElement" @submit.prevent="submitData">
            <div class="modal-body">
             
  
           
  
            
              <!--end::loading-data-->
              <!-- <h5>{{ $t("areYouSure") }}</h5> -->
              <div class="mb-3" >
                <label class="col-form-label"><b>Username</b></label>
                <multiselect @search-change="asyncUser"  v-model="users" :show-labels="false"
                :options="fetchUser" placeholder="Please Select " track-by="username"
                label="username"></multiselect>
              </div>
              
              <div class="mb-3" >
                <label class="col-form-label"><b>Akses Customer Team</b></label>
                <multiselect   v-model="aksesmasters" :show-labels="false"
                :options="fetchAksesMaster" placeholder="Please Select" track-by="value"
                label="value"></multiselect>
              </div>

              <div class="mb-3" >
                <label class="col-form-label"><b>Email Notifikasi</b></label>
                <multiselect
                  multiple
                  v-model="emailNotification"
                  :options="fetchAksesMaster"
                  placeholder="Please Select"
                  track-by="value"
                  label="value"
                ></multiselect>
              </div>
  
  
           
  
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal')">
                Cancel
              </button>
              
              <div v-if="isLoading" class="d-flex align-items-center">
                <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                <strong>Loading...</strong>
              </div>
  
              <button v-if="!isLoading" class="btn btn-primary" type="submit">Save </button>
  
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
    
  <script>
  import multiselect from "vue-multiselect";
  export default {
    components: {
        "multiselect": multiselect
    },
    name: "modalAccessMPR",
    emits: ["closeModal"],
  
    props: {
      showModal: Boolean,
      data: null,
      type: String,
    },
    watch: {
      showModal: {
        handler(newVal) {
          console.log(this.data);
          //this.loadForCarrier(this.forms.transporter)
          this.active = newVal;
          const body = document.querySelector("body");
          this.showModal
            ? body.classList.add("modal-open")
            : body.classList.remove("modal-open");
            if(newVal==false){
              this.users='';
              this.aksesmasters='';
              this.emailNotification='';
            }else{
              if(this.type=='Edit'){
                this.users={
                  user_id:this.data.user_id,
                  username:this.data.user.username,
                }
                this.aksesmasters={
                  master_lookup_detail_id:this.data.customer_team.master_lookup_detail_id,
                  value:this.data.customer_team.value
    
                }
                
                this.emailNotification=[];
                for (let index = 0; index < this.data.email_notifications.length; index++) {
                  const element = this.data.email_notifications[index];
                  this.emailNotification.push({
                    master_lookup_detail_id:element.email_notification.master_lookup_detail_id,
                    value:element.email_notification.value
                  });
                }
              }
            }
        },
        immediate: true,
        deep: true,
      },
    },
    data() {
      return {
        users:'',
        fetchUser:[],
        aksesmasters:'',
        fetchAksesMaster:[],
        fetchEmailNotification:[],
        emailNotification:'',
        isLoading: false,
        active: this.showModal,
        forms: [],
      };
    },
    methods: {
      resetForm() {
        this.forms = [];
      },
  
      loadForUsers() {
        const baseURI =
          this.$settings.endPoint + `user`;
        return this.$http.get(baseURI).then((response) => {
          // console.log(response.data.datas.data);
            this.fetchUser=response.data.datas.data;
            //console.log(this.forms.master_carrier_id);
        });
      },


      asyncUser(ev) {
        this.fetchUser=[];
        const baseURI =
          this.$settings.endPoint + `user?name=`+ev;
        return this.$http.get(baseURI).then((response) => {
          // console.log(response.data.datas.data);
            this.fetchUser=response.data.datas.data;
            //console.log(this.forms.master_carrier_id);
        });
      },

      
      loadMasterAksesLoop() {
       
          const baseURI = this.$settings.endPointMPR+`v1/mpr/lookup/customer-team`;
        return this.$http.get(baseURI).then((response) => {

          
          // console.log(response.data.datas.data);
            this.fetchAksesMaster=response.data.datas;
            //console.log(this.forms.master_carrier_id);
        });
      },
     
      submitData() {

        const emailNotificationIds = this.emailNotification.map(
            (item) => item.master_lookup_detail_id
        );
          

        var dataSend={

            "access_customer_team_id" :this.aksesmasters.master_lookup_detail_id,
            "user_id": this.users.user_id,
            "email_notifications": emailNotificationIds

          }

          console.log(dataSend);

        if(this.type=='Tambah'){

         
        
      
          this.isLoading=true;
          const baseURI = this.$settings.endPointMPR + "v1/mpr/access/create";
            this.$http
              .post(baseURI, dataSend)
              .then((response) => {
                this.isLoading=false;
                if (response.data.status === 200) {
                
                  this.resetForm();
                  this.$emit("closeModal");
                  this.loadSuccess("Success");
                  this.users='';
                  this.aksesmasters='';
                  this.emailNotification='';
                } else {
                  
                  this.errors = response.data.errors;
                  //this.resultError(response.data.errors);
                  this.loadError(this.errors.message)
                }
    
    
              })
              .catch((error) => {
                this.isloading = false;
                if (error.response) {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  
                  }
                }
                
              });

        }else{
         
        
      
          this.isLoading=true;
          const baseURI = this.$settings.endPointMPR + "v1/mpr/access/update/"+this.data.acc_id;
            this.$http
              .patch(baseURI, dataSend)
              .then((response) => {
                this.isLoading=false;
                if (response.data.status === 200) {
                
                  this.resetForm();
                  this.$emit("closeModal");
                  this.loadSuccess("Success");
                  this.users='';
                  this.aksesmasters='';
                  this.emailNotification='';
                } else {
                  
                  this.errors = response.data.errors;
                  //this.resultError(response.data.errors);
                  this.loadError(this.errors.message)
                }
    
    
              })
              .catch((error) => {
                this.isloading = false;
                if (error.response) {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  
                  }
                }
                
              });
        }

       
  
      },
  
      resultError(data) {
        var html = "";
        var count = Object.keys(data).length;
        for (var x = 0; x < count; x++) {
          var nameOb = Object.keys(data)[x];
          var objectData = data[nameOb];
          for (var y = 0; y < objectData.length; y++) {
            html += objectData[y] + "<br>";
          }
        }
  
        this.loadError(html);
      },
  
      loadError(pesan) {
        this.$swal({
          backdrop: true,
          title: "Warning",
          html: pesan,
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.value) {
            this.isLoading=false;
          }
        })
      },
  
  
  
      loadSuccess(pesan) {
        this.$swal({
          backdrop: true,
          title: "Success",
          html: pesan,
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.value) {
            this.isLoading=false;
          }
        })
      },
  
     
    },
    created: function () {
      //this.loadDriver();
  
    },
    mounted() {
      this.loadForUsers();
      this.loadMasterAksesLoop();

      const formElement = this.$refs.formElement;
        if (formElement) {
          const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
              if (mutation.type === 'attributes' && mutation.attributeName === 'readonly') {
                const inputs = formElement.querySelectorAll('.multiselect__input');
                inputs.forEach(input => {
                  input.removeAttribute('readonly');  // Hapus readonly setiap kali diubah
                });
              }
            });
          });

          const inputs = formElement.querySelectorAll('.multiselect__input');
          inputs.forEach(input => {
            observer.observe(input, { attributes: true });
          });
        }


      //tail(this.data);
    },
  };
  </script>
    
  <style scoped>
  .modal {
    background: rgba(0, 0, 0, 0.4);
  }
  
  .modal-content {
    background: #fff;
    border-radius: 0px;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 0px;
    width: 100%;
    height: 100%;
    margin-left: 0px !important;
  }
  </style>