<template>
    <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Selesaikan Pengajuan</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')"
              aria-label="btn-close"></button>
          </div>
  
          <form ref="formElement" class="forms-sample" method="POST" @submit.prevent="submitData">
            <div class="modal-body">
    
              <!--end::loading-data-->
              <!-- <h5>{{ $t("areYouSure") }}</h5> -->

              <div class="mb-3">
                <label class="form-label">Alokasi Driver</label>
                <multiselect @input="cekAlokasiDriver" @search-change="asyncSearchDriver" v-model="driver" :custom-label="customLabel"
                    :show-labels="false" :options="fetchDriver" placeholder="Search By Nmk" label="driver_id"
                    track-by="driver_id"></multiselect>
              </div>


                <div class="mb-3">
                  <label class="col-form-label">Tanggal Mulai</label>
                  <Datepicker
                    v-model="forms.assign_date"
                 
                    :bootstrap-styling="true"
                
                    format="dd-MM-yyyy"
                    placeholder="Please Select Date..."
                  ></Datepicker>
                </div>
              
  

              <div class="mb-3">
                <label class="form-label">Catatan DM</label>
                <textarea class="form-control" required v-model="forms.remarks_close" height="200px"></textarea>
              </div>




            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal')">
                No
              </button>
              
              <div v-if="isLoading" class="d-flex align-items-center">
                <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                <strong>Loading...</strong>
              </div>
  
              <button v-if="!isLoading" class="btn btn-primary" type="submit">Yes </button>
  
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
    
  <script>
  import moment from "moment";
  import multiselect from "vue-multiselect";
  import Datepicker from "vuejs-datepicker";

  export default {
  
    components: {
        "multiselect": multiselect,
        Datepicker
    },
    name: "ModalFormCancelFPPK",
    emits: ["closeModal"],
  
    props: {
      showModal: Boolean,
      data: null,
      master_mpr_detail_id: null,
    },
    watch: {
      showModal: {
        
        handler(newVal) {
          if (this.data != '') {
           
  
            // const startDate = this.data.delivery_date;
            // const endDate = new Date();
  
            // const startDate2 = this.data.return_date;
            // const endDate2 = new Date();
  
            this.forms = 
            {
                assign_driver  : '',
                assign_name_driver  : '',
                assign_date  :'',
                remarks_close  : ''
            }

  
  
  
          } else {
  
            //const startDate = new Date();
            //const endDate = new Date();
            this.forms = {
                assign_driver  : '',
                assign_name_driver  : '',
                assign_date  :'',
                remarks_close  : ''
            };
  
  
          }
  
          this.active = newVal;
          const body = document.querySelector("body");
          this.showModal
            ? body.classList.add("modal-open")
            : body.classList.remove("modal-open");
        },
        immediate: true,
        deep: true,
      },
    },
    data() {
      return {
        fetchDriver: [],
        driver: "",
        isLoading: false,
        active: this.showModal,
        forms: [],
        locale: [],
        return_date: [],
        delivery_date: [],
        equipment_name: [],
        fetchEquipmentName: [],
      };
    },
    methods: {

    resetForm() {
        this.forms = [];
    },

    cekAlokasiDriver(ev){
        this.forms.assign_driver=ev.driver_id;
        this.forms.assign_name_driver=ev.driver_name;
    },

    loadDriver() {
       
            const baseURI =
            this.$settings.endPointCt + `master-driver/select`;
        return this.$http.get(baseURI).then((response) => {
            this.fetchDriver = response.data.datas.data;

        });
    
    
    },

    customLabel(ev) {
      return `${ev.driver_id} – ${ev.driver_name}`
    },

    asyncSearchDriver(ev) {
      this.fetchDriver = [];
      const baseURI =
        this.$settings.endPointCt + `master-driver/select?driver_id=` + ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchDriver = response.data.datas.data;
      });
    },

  
      submitData() {
        this.forms.assign_date=moment(String(this.forms.assign_date)).format("YYYY-MM-DD");
        this.isLoading=true;
        const baseURI = this.$settings.endPointMPR + "v1/mpr/penerimaan/close/"+this.master_mpr_detail_id;
          this.$http
            .post(baseURI, this.forms)
            .then((response) => {
              this.isLoading=false;
              if (response) {
              
                this.$emit("closeModal");
                this.loadSuccess("Success");
              } else {
               
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
  
  
            })
            .catch((error) => {
                this.isloading = false;
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                
                }
              }
             
            });
  
      },
  
  
      submitToCt(row) {
       console.log(row);
       //this.fade(true);
       const baseURI = this.$settings.endPointCt + "master-truck/update-truck-statusupdate-truck-status-for-fppk"
         .post(baseURI, row)
         .then((response) => {
          console.log(response)
           this.loading();
           if (response.data.status === 200) {
             // this.updateStatus(row.fppk_id, 'Pengajuan')
  
           } else {
             this.errors = response.data.errors;
             this.resultError(response.data.errors);
           }
         })
         .catch((error) => {
           this.loading();
           if (error.response) {
             if (error.response.status === 422) {
               this.errors = error.response.data.errors;
               if (this.errors.message) {
                 this.error(this.errors.message);
               } else {
                 this.resultError(error.response.data.errors);
               }
             } else if (error.response.status === 500) {
               this.error(error.response.data.errors.message);
             } else {
               this.$router.push("/page-not-found");
             }
           }
           //this.resetForm();
         });
     },
  
  
      resultError(data) {
        var html = "";
        var count = Object.keys(data).length;
        for (var x = 0; x < count; x++) {
          var nameOb = Object.keys(data)[x];
          var objectData = data[nameOb];
          for (var y = 0; y < objectData.length; y++) {
            html += objectData[y] + "<br>";
          }
        }
  
        this.loadError(html);
      },
  
      loadError(pesan) {
        this.$swal({
          backdrop: true,
          title: "Warning",
          html: pesan,
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.value) {
            this.isLoading=false;
          }
        })
      },
  
  
  
      loadSuccess(pesan) {
        this.$swal({
          backdrop: true,
          title: "Success",
          html: pesan,
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.value) {
            this.isLoading=false;
          }
        })
      },
  
     
    },
    created: function () {
      //this.loadDriver();
  
    },
    mounted() {

        const formElement = this.$refs.formElement;
        if (formElement) {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
            if (mutation.type === 'attributes' && mutation.attributeName === 'readonly') {
                const inputs = formElement.querySelectorAll('.multiselect__input');
                inputs.forEach(input => {
                input.removeAttribute('readonly');  // Hapus readonly setiap kali diubah
                });
            }
            });
        });

        const inputs = formElement.querySelectorAll('.multiselect__input');
        inputs.forEach(input => {
            observer.observe(input, { attributes: true });
        });
        }


        this.loadDriver();
      //tail(this.data);
    },
  };
  </script>
    
  <style scoped>
  .modal {
    background: rgba(0, 0, 0, 0.4);
  }
  
  .modal-content {
    background: #fff;
    border-radius: 0px;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 0px;
    width: 100%;
    height: 100%;
    margin-left: 0px !important;
  }
  </style>