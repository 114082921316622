<template>
    <div>
      <div class="page-content">
        <div class="col-lg-12 col-xl-12 stretch-card">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-lg-12">
                  <h4 style="margin-right: 5px" class="float-start">
                    Akses Dan Notifikasi
                  </h4>
  
                  <button v-if="permision_role.method_create" type="button" @click="addData" class="btn-success btn btn-sm float-end">
                    <i class="link-icon" data-feather="plus"></i> 
                   Tambah Baru
                  </button>
                </div>
  
             
              </div>
            </div>
            <div class="card-body">
              
              <vue-good-table
                    title="list-user"
                    mode="remote"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    :totalRows="totalRecords"
                    :line-numbers="true"
                    :isLoading.sync="isLoading"
                    :pagination-options="{
                      enabled: true,
                    }"
                    :select-options="{
                      enabled: true,
                      selectOnCheckboxOnly: true,
                      selectionText: $t('data_selected'),
                      clearSelectionText: $t('clear'),
                    }"
                    :rows="rows"
                    :columns="columns"
                  >
                    <template slot="table-row" slot-scope="props">
                      
                      <span v-if="props.column.field == 'email_notif'">
                        {{ formatEmailNotifications(props.row.email_notifications) }}
                      </span>
                      <span v-if="props.column.field == 'actions'">
                       
  
                        <a
                          href="#"
                          title="Edit"
                          
                          @click.prevent="editData(props.index, props.row)"
                        >
                          <i
                            class="mdi mdi-square-edit-outline"
                            style="font-size: 16px"
                          ></i>
                        </a>

                        <a
                          href="#"
                    
                          title="Delete"
                          @click.prevent="deleteData(props.index, props.row)"
                        >
                          <i
                            class="mdi mdi-trash-can-outline"
                            style="font-size: 16px"
                          ></i>
                        </a>

  
                      
                      </span>
                      <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                      </span>
                    </template>
                  </vue-good-table>
            </div>
  
  
            <modal-access-mpr :showModal="showModal" :type="type" :data="data"
                @closeModal="closeMyModal"></modal-access-mpr>
  
          </div>
        </div>
      </div>
  
      
    </div>
  </template>
  
  <script>
  import moment from "moment";
  // import DateRangePicker from "vue2-daterange-picker";
  // import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
  import modalAccessMPR from "@/components/modal/modalAccessMpr.vue";
  
  export default {
    name: "MprPengajuan",
    components: {

      "modal-access-mpr": modalAccessMPR,
    },
    data() {
      // const startDate = moment(Date.now()).format("YYYY-MM-DD");
      // //const startDate = new Date();
      // const endDate = moment(Date.now()).format("YYYY-MM-DD");
  
      const startDate = moment().subtract(7, 'd').format('YYYY-MM-DD');
      //const startDate = new Date();
      const endDate = moment(Date.now() + 7 * 24 * 3600 * 1000).format('YYYY-MM-DD'); 
  
      return {
        type:'Tambah',
        fppk_id:'',
        showModalCancelFppk:false,
        showModal:false,
        data:'',
        // customRange: {
        //   Today: [today, today],
        //   Yesterday: [yesterday, yesterday],
        //   "This Week": [
        //     new Date(current.setDate(firstDay)),
        //     new Date(current.setDate(current.getDate() + 6)),
        //   ],
        //   "Last  Week": [
        //     new Date(current.setDate(firstDay - 7)),
        //     new Date(current.setDate(firstDay - 1)),
        //   ],
        //   "This Month": [
        //     new Date(today.getFullYear(), today.getMonth(), 1),
        //     new Date(today.getFullYear(), today.getMonth() + 1, 0),
        //   ],
        //   "Last month": [
        //     new Date(today.getFullYear(), today.getMonth() - 1, 1),
        //     new Date(today.getFullYear(), today.getMonth(), 0),
        //   ],
        //   "This year": [
        //     new Date(today.getFullYear(), 0, 1),
        //     new Date(today.getFullYear(), 11, 31),
        //   ],
        // },
        dateRange: {
          startDate: startDate, // 7 days backward from today
          endDate: endDate, 
        },
        statusFilter:[
          {
            text:"Draft",
            value:"Draft"
          },
          {
            text:"Open",
            value:"Open"
          },
          {
            text:"Cancelled",
            value:"Cancelled" 
          },
          {
            text:"Expired",
            value:"Expired"
          },
          {
            text:"In Progress",
            value:"In Progress"
          },
          {
            text:"Closed",
            value:"Closed"
          },
          {
            text:"Rejected",
            value:"Rejected"
          },
        ],
        status: [],
        statusAll:true,
        searchBy :[
          {
            text:"Request Number",
            field:"request_number"
          },
          {
            text:"Nopol",
            field:"nopol"
          },
          
        ],
        search:[],
        serverParams: {
          columnFilters: {},
          sort: {
            field: "",
            type: "",
          },
          page: 1,
          per_page: 10,
        },
        columns: [
            
            {
              label: "Username",
              field: "user.username",
              width: "100px",
              filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By ", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
              },
            },

            {
              label: "Akses Customer Team",
              field: "customer_team.value",
              width: "100px",
              filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By ", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
              },
            },

            {
              label: "Email Notif",
              field: "email_notif",
              width: "100px",
              filterOptions: {
                enabled: true, // enable filter for this column
                placeholder: "Filter By ", // placeholder for filter input
                filterValue: "", // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                trigger: "enter", //only trigger on enter not on keyup
              },
            },

            // {
            //   label: "Auto Mail",
            //   field: "auto_mail",
            //   width: "100px",
            //   filterOptions: {
            //     enabled: true, // enable filter for this column
            //     placeholder: "Filter By ", // placeholder for filter input
            //     filterValue: "", // initial populated value for this filter
            //     filterDropdownItems: [], // dropdown (with selected values) instead of text input
            //     trigger: "enter", //only trigger on enter not on keyup
            //   },
            // },

            {
              label: "Action",
              field: "actions",
              width: "100px",
            },


           
        ],
        rows:[],
        totalRecords:0,
        maxToasts: 100, 
        position: "up right",
        closeBtn: true,
        isLoading: false,
        errors: [],
        permision_role: [],
        langs: ["id", "en"],
        opens: "center",
        locale: {
          format: "yyyy-mm-dd",
        },
      };
    },
    methods: {
      addData(){
        this.type="Tambah"

        this.showModal=true;
        this.data=[];
      },
      checkAll(ev){
        if(ev==true){
          this.status=['Draft','Pengajuan','Mulai Service', 'Selesai Service', 'Expired', 'Cancelled', 'Reopen']
        }else{
          this.status=[]
        }
        
        // console.log(ev)
      },
      onColumnFilter(params) {
        params.page = 1;
        this.updateParams(params);
      },
  
      onPageChange(params) {
        this.updateParams({ page: params.currentPage });
      },

      

      formatEmailNotifications(emailNotifications) {
        if (!emailNotifications || emailNotifications.length === 0) {
          return "-"; // Jika kosong, tampilkan "-"
        }
        return emailNotifications
          .map((notif) => notif.email_notification.value)
          .join(", ");
      },
    
  
      submitToCt(row) {
       
        this.fade(true);
        const baseURI = this.$settings.endPointCt + "master-truck/update-truck-status-for-fppk";
        this.$http
          .post(baseURI, row)
          .then((response) => {
            this.loading();
            if (response.data.status === 200) {
              // this.updateStatus(row.fppk_id, 'Pengajuan')
  
            } else {
              this.errors = response.data.errors;
              this.resultError(response.data.errors);
            }
          })
          .catch((error) => {
            this.loading();
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                if (this.errors.message) {
                  this.error(this.errors.message);
                } else {
                  this.resultError(error.response.data.errors);
                }
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }
            //this.resetForm();
          });
      },
  
  
      updateStatus(row,status) {
        this.$swal({
          title: this.$t("areYouSure"),
          text: 'Update Status to: '+status,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        }).then((result) => {
          if (result.value) {
            if(status=='Pengajuan'){
  
              var keluhanArray = [
                              row.keluhan1,
                              row.keluhan2,
                              row.keluhan3,
                              row.keluhan4,
                              row.keluhan5,
                              row.keluhan6
                          ].filter(keluhan => keluhan !== undefined && keluhan !== null);
  
              
              var dataSendCT={
               
                "vehicle_no":row.nopol,
                "status_time":moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                "master_status_id": 8,
                "reference":row.no_fppk,
                "status_type":'Pengajuan',
                "estimation_date": row.tgl_estimasi_tiba,
                "fppk_keluhan":keluhanArray.join(';')
              };
              this.submitToCt(dataSendCT);
            }
  
            const baseURI = this.$settings.endPointFppk + "v1/pengajuan-fppk/update-status-fppk/"+row.fppk_id;
          this.$http
            .post(baseURI, {
              status:status
            })
            .then((response) => {
              this.isLoading=false;
              if (response.data.status === 200) {
  
                this.loadItems()
                this.success("Success");
               
              } else {
               
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
  
  
            })
            .catch((error) => {
  
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                
                }
              }
              this.isloading = false;
            });
          }
  
        })
        
  
      },
  
      resetBtn(){
        // this.dateRange= {
        //   startDate:null,
        //   endDate:null
        // };
        this.search.text='';
        this.search.filterBy='';
        this.status=[];
        this.loadItems();
      },
  
      searchFunction(){
        this.row=[];
        var data={};
  
        if(this.status.length > 0 ){
          data["status_fppks"]=this.status;
        }
  
        // var data={
        //     "status_fppks" : dataStatus
        // }
  
        if(this.dateRange.startDate!=undefined || this.dateRange.endDate!=undefined){
          data['tanggal_fppk']=moment(this.dateRange.startDate).format("YYYY-MM-DD")+'~'+moment(this.dateRange.endDate).format("YYYY-MM-DD")
        }
        
        data[this.search.filterBy]=this.search.text
        // console.log(data);
  
        this.fade(true);
        const baseURI = this.$settings.endPointFppk + "v1/pengajuan-fppk";
      
        return this.$http
          .post(
            baseURI +
              `?limit=${this.serverParams.per_page}&page=${
                this.serverParams.page
              }&sort_field=${this.serverParams.sort.field}&sort_type=${
                this.serverParams.sort.type
              }
            `,data
          )
          .then((response) => {
            this.rows = response.data.datas.data;
            this.totalRecords = response.data.datas.total;
            this.loading();
          }) .catch((error) => {
            this.loading();
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                if (this.errors.message) {
                  this.error(this.errors.message);
                } else {
                  this.resultError(error.response.data.errors);
                }
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }
            //this.resetForm();
          });
       
      },
  
  
      detailData(index, row) {
        var params = this.$onRandom(row.fppk_id);
        window.location.href = "/mpr-akses/detail/" + params;
      },
  
      editData(index, row) {     
        this.showModal=true;
        this.data=row;
        this.type="Edit"
      },
  
      deleteData(index,row){
        this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI =
            this.$settings.endPointMPR + "v1/mpr/access/delete/" + row.acc_id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
      },

      editDataRevisi(index, row) {
        var params = this.$onRandom(row.fppk_id);
        window.location.href = "/mpr-akses/edit/" + params+"?revisi=true";
      },
  
      onSortChange(params) {
        this.updateParams({
          sort: {
            type: params[0].type,
            field: params[0].field,
          },
        });
      },
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
        this.loadItems();
      },
      onPerPageChange(params) {
        this.updateParams({ per_page: params.currentPerPage });
      },
  
      closeMyModal() {
        this.showModal = false;
        this.loadItems();
      },
  
      loadItems() {
      
        this.fade(true);
        const baseURI = this.$settings.endPointMPR+`v1/mpr/access/index`;
        console.log(baseURI);
        return this.$http
          .get(
            baseURI+
              `?limit=${this.serverParams.per_page}&page=${
                this.serverParams.page
              }&sort_field=${this.serverParams.sort.field}&sort_type=${
                this.serverParams.sort.type
              }
            `
          )
          .then((response) => {
          
            this.rows = response.data.datas.data;
            this.totalRecords = response.data.datas.total;
            this.loading();
          });
      },
  
      fetchIt() {
        const userDatas = this.$getUserInfo();
        this.detailUser = userDatas.sub;
        // this.permision_role = userDatas.sub.permision_role;
        let rolePermision = [];
        userDatas.sub.permision_role.forEach(function (permision) {
          if (permision.permission.controller == "MprAccessController") {
            rolePermision = permision;
            // console.log(permision);
          }
        });
  
        if (!rolePermision.method_read) {
          this.$router.push("/authorized-error");
        }
        this.permision_role = rolePermision;
      },
  
      resultError(data) {
        var count = Object.keys(data).length;
        for (var x = 0; x < count; x++) {
          var nameOb = Object.keys(data)[x];
          var objectData = data[nameOb];
          for (var y = 0; y < objectData.length; y++) {
            this.error(objectData[y]);
          }
        }
      },
  
      success(kata) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          title: kata,
        });
      },
  
      error(kata) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: kata,
        });
      },
  
      btnModalShowCancel(row){
        this.fppk_id=row.fppk_id
        this.dataFppk=row
        this.showModalCancelFppk=true
      },
  
      fade(sType) {
        this.isLoading = sType;
      },
  
      loading() {
        this.fade(true);
        setTimeout(() => {
          this.isLoading = false;
        }, 1000); // hide the message after 3 seconds
      },
    },
  
    mounted() {
      this.loadItems();
      this.fetchIt();
      this.loading();
    },
  };
  </script>
  
  <style>
  </style>