<template>
  <div>
    <div class="page-content">
      <div class="col-lg-12 col-xl-12 stretch-card">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-lg-12">
                <h4 style="margin-right: 5px" class="float-start">
                  MPR Approval
                </h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row my-4">
              <div class="col-sm-1">
                <label class="form-label">Status</label>
              </div>
              <div class="col-sm-10">
                <div class="mb-1">
                  <div class="form-check form-check-inline">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="all"
                      v-model="statusAll"
                      @change="checkAll($event.target.checked)"
                    />
                    <label class="form-check-label" for="all"> All </label>
                  </div>
                  <div
                    class="form-check form-check-inline"
                    v-for="select in statusFilter"
                    :key="select.value"
                  >
                    <input
                      type="checkbox"
                      class="form-check-input"
                      :id="select.value"
                      :value="select.value"
                      :name="select.value"
                      v-model="status"
                    />
                    <label class="form-check-label" :for="select.value">
                      {{ select.text }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row my-3">
              <div class="col-lg-3">
                <label class="form-label">Date Range</label>
                <date-range-picker
                  style="width: 100%"
                  control-container-class="form-control"
                  :locale-data="locale"
                  v-model="dateRange"
                  :opens="opens"
                  :showDropdowns="true"
                  :singleDatePicker="false"
                  :timePicker24Hour="false"
                  :timePicker="false"
                  :showWeekNumbers="false"
                >
                </date-range-picker>
              </div>
              <div class="col-lg-2">
                <label class="form-label">Filter By</label>
                <v-select
                  :options="searchBy"
                  label="text"
                  :reduce="(filter) => filter.field"
                  v-model="search.filterBy"
                >
                </v-select>
              </div>
              <div class="col-lg-3">
                <label class="form-label">Search</label>
                <input type="text" class="form-control" v-model="search.text" />
              </div>
              <div class="col-lg-2">
                <div class="mb-5">
                  <button
                    style="margin-right: 10px"
                    class="btn btn-primary mt-4"
                    type="button"
                    @click="loadItems"
                  >
                    Search
                  </button>
                  <button
                    class="btn btn-warning mt-4"
                    type="button"
                    @click="resetBtn"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>

            <vue-good-table
              title="list-user"
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :totalRows="totalRecords"
              :line-numbers="true"
              :isLoading.sync="isLoading"
              :pagination-options="{
                enabled: true,
              }"
              :rows="rows"
              :columns="columns"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'actions'">
                  <a
                    href="#"
                    title="Detail"
                    @click.prevent="detailData(props.index, props.row)"
                  >
                    <i class="mdi mdi-eye-outline" style="font-size: 16px"></i>
                  </a>
                </span>
                <span v-if="props.column.field == 'vehicle_no'">
                  {{ showVehicleNo(props.row.pluck_details) }}
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>

          <modal-cancel-fppk
            :showModal="showModalCancelFppk"
            :fppk_id="fppk_id"
            :data="dataFppk"
            @closeModal="closeMyModal"
          ></modal-cancel-fppk>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import modalCancelFppk from "@/components/modal/modalCancelFppk.vue";

export default {
  name: "MprPengajuan",
  components: {
    DateRangePicker,
    "modal-cancel-fppk": modalCancelFppk,
  },
  data() {
    // const startDate = moment(Date.now()).format("YYYY-MM-DD");
    // //const startDate = new Date();
    // const endDate = moment(Date.now()).format("YYYY-MM-DD");

    const startDate = moment().subtract(7, "d").format("YYYY-MM-DD");
    //const startDate = new Date();
    const endDate = moment(Date.now() + 7 * 24 * 3600 * 1000).format(
      "YYYY-MM-DD"
    );

    return {
      fppk_id: "",
      showModalCancelFppk: false,
      dataFppk: "",
      // customRange: {
      //   Today: [today, today],
      //   Yesterday: [yesterday, yesterday],
      //   "This Week": [
      //     new Date(current.setDate(firstDay)),
      //     new Date(current.setDate(current.getDate() + 6)),
      //   ],
      //   "Last  Week": [
      //     new Date(current.setDate(firstDay - 7)),
      //     new Date(current.setDate(firstDay - 1)),
      //   ],
      //   "This Month": [
      //     new Date(today.getFullYear(), today.getMonth(), 1),
      //     new Date(today.getFullYear(), today.getMonth() + 1, 0),
      //   ],
      //   "Last month": [
      //     new Date(today.getFullYear(), today.getMonth() - 1, 1),
      //     new Date(today.getFullYear(), today.getMonth(), 0),
      //   ],
      //   "This year": [
      //     new Date(today.getFullYear(), 0, 1),
      //     new Date(today.getFullYear(), 11, 31),
      //   ],
      // },
      dateRange: {
        startDate: startDate, // 7 days backward from today
        endDate: endDate,
      },
      statusFilter: [
        {
          text: "Open",
          value: "Open",
        },

        {
          text: "Expired",
          value: "Expired",
        },
        {
          text: "In Progress",
          value: "In Progress",
        },
        {
          text: "Closed",
          value: "Closed",
        },
        {
          text: "Rejected",
          value: "Rejected",
        },
      ],
      status: [],
      statusAll: false,
      searchBy: [
        {
          text: "Request Number",
          field: "request_no",
        },
        {
          text: "Nopol",
          field: "vehicle_no",
        },
      ],
      search: {
        filterBy: "nopol",
        text: "",
      },
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      columns: [
        {
          label: "Action",
          field: "actions",
          width: "100px",
        },

        {
          label: "Request No",
          field: "request_no",
          width: "100px",
        },

        {
          label: "Customer Team",
          field: "customer_team",
          width: "100px",
        },

        // {
        //   label: "Request Date",
        //   field: "created_at",
        //   width: "100px",
        // },

        // {
        //   label: "Approve By",
        //   field: "approve_by",
        //   width: "100px",
        // },

        // {
        //   label: "Approve Date",
        //   field: "approve_date",
        //   width: "100px",
        // },

        {
          label: "Status",
          field: "status",
          width: "100px",
        },
        {
          label: "Nopol",
          field: "vehicle_no",
          width: "100px",
        },
      ],
      rows: [],
      totalRecords: 0,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
      permision_role: [],
      langs: ["id", "en"],
      opens: "center",
      locale: {
        format: "yyyy-mm-dd",
      },
    };
  },
  methods: {
    sendData(index, row) {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          const baseURI =
            this.$settings.endPointMPR +
            "v1/mpr/pengajuan/submit-to-open/" +
            row.master_mpr_id;
          this.$http
            .patch(baseURI)
            .then((response) => {
              this.isLoading = false;
              if (response.data.status === 200) {
                var resp = response.data.datas.messages;
                this.success(resp);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                //this.resultError(response.data.errors);
                this.loadError(this.errors.message);
              }
            })
            .catch((error) => {
              this.isloading = false;
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                }
              }
            });
        }
      });
    },
    cancelData(index, row) {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataCancel"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          const baseURI =
            this.$settings.endPointMPR +
            "v1/mpr/pengajuan/submit-to-cancel/" +
            row.master_mpr_id;
          this.$http
            .patch(baseURI)
            .then((response) => {
              this.isLoading = false;
              if (response.data.status === 200) {
                var resp = response.data.datas.messages;
                this.success(resp);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                //this.resultError(response.data.errors);
                this.loadError(this.errors.message);
              }
            })
            .catch((error) => {
              this.isloading = false;
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                }
              }
            });
        }
      });
    },
    reOpenData(index, row) {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          const baseURI =
            this.$settings.endPointMPR +
            "v1/mpr/pengajuan/submit-to-reopen/" +
            row.master_mpr_id;
          this.$http
            .patch(baseURI)
            .then((response) => {
              this.isLoading = false;
              if (response.data.status === 200) {
                var resp = response.data.datas.messages;
                this.success(resp);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                //this.resultError(response.data.errors);
                this.loadError(this.errors.message);
              }
            })
            .catch((error) => {
              this.isloading = false;
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                }
              }
            });
        }
      });
    },
    showVehicleNo(data) {
      var string = "";
      if (data.length > 0) {
        for (let index = 0; index < data.length; index++) {
          if (index != 0 && index != data.length) {
            string += ";";
          }
          string += data[index].vehicle_no;
        }
      }
      return string;
    },
    addData() {
      window.location.href = "/mpr-pengajuan/add";
    },
    checkAll(ev) {
      if (ev == true) {
        this.status = [
          "Draft",
          "Open",
          "Cancelled",
          "In Progress",
          "Expired",
          "Closed",
          "Rejected",
        ];
      } else {
        this.status = [];
      }

      // console.log(ev)
    },
    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    submitToCt(row) {
      this.fade(true);
      const baseURI =
        this.$settings.endPointCt + "master-truck/update-truck-status-for-fppk";
      this.$http
        .post(baseURI, row)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            // this.updateStatus(row.fppk_id, 'Pengajuan')
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              if (this.errors.message) {
                this.error(this.errors.message);
              } else {
                this.resultError(error.response.data.errors);
              }
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },

    updateStatus(row, status) {
      this.$swal({
        title: this.$t("areYouSure"),
        text: "Update Status to: " + status,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          if (status == "Pengajuan") {
            var keluhanArray = [
              row.keluhan1,
              row.keluhan2,
              row.keluhan3,
              row.keluhan4,
              row.keluhan5,
              row.keluhan6,
            ].filter((keluhan) => keluhan !== undefined && keluhan !== null);

            var dataSendCT = {
              vehicle_no: row.nopol,
              status_time: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
              master_status_id: 8,
              reference: row.no_fppk,
              status_type: "Pengajuan",
              estimation_date: row.tgl_estimasi_tiba,
              fppk_keluhan: keluhanArray.join(";"),
            };
            this.submitToCt(dataSendCT);
          }

          const baseURI =
            this.$settings.endPointFppk +
            "v1/pengajuan-fppk/update-status-fppk/" +
            row.fppk_id;
          this.$http
            .post(baseURI, {
              status: status,
            })
            .then((response) => {
              this.isLoading = false;
              if (response.data.status === 200) {
                this.loadItems();
                this.success("Success");
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                }
              }
              this.isloading = false;
            });
        }
      });
    },

    resetBtn() {
      this.search.text = "";
      this.search.filterBy = "";
      this.status = [];
      this.loadItems();
    },

    searchFunction() {
      this.row = [];
      var data = {};

      if (this.status.length > 0) {
        data["status_fppks"] = this.status;
      }

      // var data={
      //     "status_fppks" : dataStatus
      // }

      if (
        this.dateRange.startDate != undefined ||
        this.dateRange.endDate != undefined
      ) {
        data["tanggal_fppk"] =
          moment(this.dateRange.startDate).format("YYYY-MM-DD") +
          "~" +
          moment(this.dateRange.endDate).format("YYYY-MM-DD");
      }

      data[this.search.filterBy] = this.search.text;
      // console.log(data);

      this.fade(true);
      const baseURI = this.$settings.endPointMPR + "v1/mpr/pengajuan/index";

      return this.$http
        .get(
          baseURI +
            `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}
            `,
          data
        )
        .then((response) => {
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
          this.loading();
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              if (this.errors.message) {
                this.error(this.errors.message);
              } else {
                this.resultError(error.response.data.errors);
              }
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },

    detailData(index, row) {
      var params = this.$onRandom(row.master_mpr_id);
      window.location.href = "/mpr-approval/detail/" + params;
    },

    editData(index, row) {
      var params = this.$onRandom(row.master_mpr_id);
      window.location.href = "/mpr-pengajuan/edit/" + params;
    },

    editDataRevisi(index, row) {
      var params = this.$onRandom(row.fppk_id);
      window.location.href = "/fppk-pengajuan/edit/" + params + "?revisi=true";
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    closeMyModal() {
      this.showModalCancelFppk = false;
      this.loadItems();
    },

    loadItems() {
      var data = {};
      if (this.status.length > 0) {
        data["status"] = this.status.join(",");
      }

      if (
        this.dateRange.startDate != undefined ||
        this.dateRange.endDate != undefined
      ) {
        data["request_date"] =
          moment(this.dateRange.startDate).format("YYYY-MM-DD") +
          "~" +
          moment(this.dateRange.endDate).format("YYYY-MM-DD");
      }

      data[this.search?.filterBy ? this.search.filterBy : ""] = this.search.text
        ? this.search.text
        : "";
      let filterUrl = "";
      for (var a in data) {
        if (a) {
          filterUrl += "&" + a + "=" + data[a];
        }
      }
      // console.log(encodeURIComponent(data));
      this.fade(true);
      const baseURI = this.$settings.endPointMPR + "v1/mpr/approval/index";

      return this.$http
        .get(
          baseURI +
            `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}${filterUrl}
            `
        )
        .then((response) => {
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
          this.loading();
        });
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "MprApprovalController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    btnModalShowCancel(row) {
      this.fppk_id = row.fppk_id;
      this.dataFppk = row;
      this.showModalCancelFppk = true;
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },
  },

  mounted() {
    this.loadItems();
    this.fetchIt();
    this.loading();
  },
};
</script>

<style></style>
