<template>
  <div
    class="modal fade"
    ref="modal"
    :class="{ show: active, 'd-block': active }"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Reject Approval</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            v-on:click="$emit('closeModal')"
            aria-label="btn-close"
          ></button>
        </div>

        <form
          class="forms-sample"
          method="POST"
          ref="formElement"
          @submit.prevent="submitData"
        >
          <div class="modal-body">
            <div class="mb-3">
                <h4>Tolak pengajuan yang dipilih ?</h4>
            </div>
            <div class="mb-3">
                <label class="col-form-label">Alasan</label>
                <textarea v-model="forms.remarks_reject" class="form-control" id=""></textarea>
            </div>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal')">
                Cancel
              </button>
              
              <div v-if="isLoading" class="d-flex align-items-center">
                <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                <strong>Loading...</strong>
              </div>
  
              <button v-if="!isLoading" class="btn btn-primary" type="submit">Save </button>
  
            </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "modalRejectApprovalMPR",
  emits: ["closeModal"],

  props: {
    showModal: Boolean,
    id:null,
    // data: null,
    // type: String,
  },
  watch: {
    showModal: {
      handler(newVal) {
        console.log(this.data);
        //this.loadForCarrier(this.forms.transporter)
        this.active = newVal;
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
        if (newVal == false) {
          this.users = "";
          this.aksesmasters = "";
          this.emailNotification = "";
        } else {
          if (this.type == "Edit") {
            this.users = {
              user_id: this.data.user_id,
              username: this.data.user.username,
            };
            this.aksesmasters = {
              master_lookup_detail_id:
                this.data.customer_team.master_lookup_detail_id,
              value: this.data.customer_team.value,
            };

            this.emailNotification = [];
            for (
              let index = 0;
              index < this.data.email_notifications.length;
              index++
            ) {
              const element = this.data.email_notifications[index];
              this.emailNotification.push({
                master_lookup_detail_id:
                  element.email_notification.master_lookup_detail_id,
                value: element.email_notification.value,
              });
            }
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      isLoading: false,
      active: this.showModal,
      forms: {
        remarks_reject : ""
      },
    };
  },
  methods: {
    submitData(){
      this.$emit('submit',this.id,this.forms);
    },
    resetForm() {
      this.forms = [];
    },

   
    resultError(data) {
      var html = "";
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          html += objectData[y] + "<br>";
        }
      }

      this.loadError(html);
    },

    loadError(pesan) {
      this.$swal({
        backdrop: true,
        title: "Warning",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = false;
        }
      });
    },

    loadSuccess(pesan) {
      this.$swal({
        backdrop: true,
        title: "Success",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = false;
        }
      });
    },
  },
  created: function () {
    //this.loadDriver();
  },
  mounted() {

    const formElement = this.$refs.formElement;
    if (formElement) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (
            mutation.type === "attributes" &&
            mutation.attributeName === "readonly"
          ) {
            const inputs = formElement.querySelectorAll(".multiselect__input");
            inputs.forEach((input) => {
              input.removeAttribute("readonly"); // Hapus readonly setiap kali diubah
            });
          }
        });
      });

      const inputs = formElement.querySelectorAll(".multiselect__input");
      inputs.forEach((input) => {
        observer.observe(input, { attributes: true });
      });
    }

    //tail(this.data);
  },
};
</script>

<style scoped>
.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 100%;
  height: 100%;
  margin-left: 0px !important;
}
</style>
