<template>
    <div>
        <!-- <div class="loader" v-if="this.isLoading"></div>
      <div class="loadertext" v-if="this.isLoading">
        {{ $t("loadingTxt") }}...
      </div> -->
        <div class="main-wrapper">
            <!--begin::sidebar-->
            <sidebar-component classMenu="Dashboard"></sidebar-component>
            <!--end::sidebar-->

            <div class="page-wrapper">
                <!--begin::navbar-->
                <navbar-component></navbar-component>
                <!--end::navbar-->

                <!--begin::content-->
                <div class="page-content">
                    <div class="
                d-flex
                justify-content-between
                align-items-center
                flex-wrap
                grid-margin
              ">
                        <div>
                            <h4 class="mb-3 mb-md-0">Dashboard MPR</h4>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-body">

                                    <Doughnut :chart-options="{
                                        plugins: {
                                            title: {
                                                display: true,
                                                text: 'Priority in Progress' // Judul grafik
                                            }
                                        },
                                        responsive: true,
                                        maintainAspectRatio: false
                                    }" :chart-data="chartDataPriority" :chart-id="'doughnut-chart'"
                                        :dataset-id-key="'label'" :plugins="[]" :css-classes="''" :styles="{}"
                                        :width="400" :height="400" />
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-body">
                                    <Bar :chart-options="{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        scales: {
                                            x: { stacked: true },
                                            y: { stacked: true }
                                        },
                                        plugins: {
                                            title: {
                                                display: true,
                                                text: 'Status'
                                            }
                                        }
                                    }" :chart-data="chartDataStatus" class="chart-canvas" />
                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="row" style="margin-top: 10px;">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">

                                    <div class="chart-and-table">
                                        <!-- Chart -->
                                        <div class="chart-container">
                                            <Bar v-if="chartDataDriverFullfiment.labels.length" :chart-options="chartOptionsDriverFullfilment"
                                                :chart-data="chartDataDriverFullfiment" class="chart-canvas-driver-fullfilment" />
                                            <p v-else class="text-center text-gray-500">Loading chart...</p>
                                        </div>

                                        <!-- Table -->
                                        <div class="table-container mt-4">
                                            <table class="table-auto w-full border-collapse border border-gray-300">
                                                <thead class="bg-gray-100">
                                                    <tr>
                                                        <th class="border border-gray-300 px-4 py-2">Type Truck</th>
                                                        <th class="border border-gray-300 px-4 py-2">Sum Of Unit</th>
                                                        <th class="border border-gray-300 px-4 py-2">Sum Of Driver
                                                            Settle</th>
                                                        <th class="border border-gray-300 px-4 py-2">Sum Of Driver
                                                            Request</th>
                                                        <th class="border border-gray-300 px-4 py-2">Sum Of Non Request
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in fetchDriverFullfilment" :key="index">
                                                        <td class="border border-gray-300 px-4 py-2">{{ item.type_truck
                                                            }}</td>
                                                        <td class="border border-gray-300 px-4 py-2">{{ item.total }}
                                                        </td>
                                                        <td class="border border-gray-300 px-4 py-2">{{
                                                            item.driverSettle }}</td>
                                                        <td class="border border-gray-300 px-4 py-2">{{
                                                            item.driverRequest }}</td>
                                                        <td class="border border-gray-300 px-4 py-2">{{ item.nonRequest
                                                            }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <p v-if="!fetchDriverFullfilment.length"
                                                class="text-center text-gray-500 mt-4">
                                                No data available.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <!--end::content-->

                <!--begin::footer-->
                <footer-component></footer-component>
                <!--end::footer-->
            </div>
        </div>
    </div>
</template>

<script>
import sidebarComponent from "../_partials/_sidebar";
import navbarComponent from "../_partials/_navbar";
import footerComponent from "../_partials/_footer";
import moment from "moment";

// import DateRangePicker from 'vue2-daterange-picker'
// import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import { Doughnut, Bar } from 'vue-chartjs'

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)



export default {
    name: "UserDashboard",
    components: {
        "sidebar-component": sidebarComponent,
        "navbar-component": navbarComponent,
        "footer-component": footerComponent,
        Doughnut, Bar
    },
    props: {


    },
    data() {
        return {
            chartDataDriverFullfiment: {
                labels: [],
                datasets: [
                    {
                        label: "Unit",
                        data: [],
                        backgroundColor: this.getRandomColorHexRgb(),
                    },
                    {
                        label: "Driver Settle",
                        data: [],
                        backgroundColor: this.getRandomColorHexRgb(),
                    },
                    {
                        label: "Driver Request",
                        data: [],
                        backgroundColor: this.getRandomColorHexRgb(),
                    },
                    {
                        label: "Non Request",
                        data: [],
                        backgroundColor: this.getRandomColorHexRgb(),
                    },
                ],
            },
            chartOptionsDriverFullfilment: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: true,
                        text: "Driver Fullfilment",
                    },
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: "Type Truck",
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: "Jumlah",
                        },
                    },
                },
            },

            title: "",
            chartDataStatus: {
                labels: [], // Tanggal
                datasets: [] // Data per status
            },
            chartDataPriority: {
                labels: [],
                datasets: [
                    {
                        backgroundColor: [],
                        data: []
                    }
                ]
            },
            fetchPriority: [],
            fetchStatus: [],
            fetchDriverFullfilment: [],
            iframe: "",
        };
    },
    computed: {
        myStyles() {
            return {
                height: "500px",
                position: "relative",
            };
        },
    },
    watch: {},
    methods: {
        formatDate(val) {
            return val ? moment(val).format("YYYY-MM-DD") : "";
        },


        getTitleById(id) {
            const item = this.titleData.find(data => data.id === id);
            this.title = item ? item.name : null;
        },

        loadPriority() {
            const baseURI = this.$settings.endPointMPR + `/v1/mpr/dashboard/get-by-priority`;
            this.$http.get(baseURI).then((response) => {
                this.fetchPriority = response.data.datas;

                // Proses data untuk chart
                const labels = this.fetchPriority.map(item => item.priority); // Ambil semua priority
                const data = this.fetchPriority.map(item => item.total); // Ambil semua total

                // Perbarui chartDataPriority
                this.chartDataPriority.labels = labels;
                this.chartDataPriority.datasets[0].data = data;

                const colors = this.fetchPriority.map(() => '#' + Math.floor(Math.random() * 16777215).toString(16));
                this.chartDataPriority.datasets[0].backgroundColor = colors;
            });
        },


        loadDriverFullfillment() {
            const baseURI = this.$settings.endPointMPR + `/v1/mpr/dashboard/get-driver-fulfillment`;
            this.$http.get(baseURI).then((response) => {
                this.fetchDriverFullfilment = response.data.datas;


                this.chartDataDriverFullfiment.labels = this.fetchDriverFullfilment.map(
                    (item) => item.type_truck
                );
                this.chartDataDriverFullfiment.datasets[0].data = this.fetchDriverFullfilment.map(
                    (item) => parseInt(item.total, 10)
                );
                this.chartDataDriverFullfimentdatasets[1].data = this.fetchDriverFullfilment.map(
                    (item) => parseInt(item.driverSettle, 10)
                );
                this.chartDataDriverFullfiment.datasets[2].data = this.fetchDriverFullfilment.map(
                    (item) => parseInt(item.driverRequest, 10)
                );
                this.chartDataDriverFullfimentdatasets[3].data = this.fetchDriverFullfilment.map(
                    (item) => parseInt(item.nonRequest, 10)
                );

            });
        },


        loadStatus() {
            const baseURI = this.$settings.endPointMPR + `/v1/mpr/dashboard/get-by-status`;
            this.$http.get(baseURI).then((response) => {
                this.fetchStatus = response.data.datas;

                // Proses data untuk chart
                const labels = [...new Set(this.fetchStatus.map(item => item.date))]; // Daftar tanggal unik
                const statusList = [...new Set(this.fetchStatus.flatMap(item => item.datas.map(data => data.status)))]; // Daftar status unik

                const datasets = statusList.map(status => {
                    return {
                        label: status, // Nama status
                        backgroundColor: this.getRandomColorHexRgb(), // Warna random
                        data: labels.map(date => {
                            const entry = this.fetchStatus.find(item => item.date === date);
                            if (entry) {
                                const statusData = entry.datas.find(data => data.status === status);
                                return statusData ? statusData.total : '';
                            }
                            return 0;
                        }),
                    };
                });

                this.chartDataStatus.labels = labels;
                this.chartDataStatus.datasets = datasets;

                


            });
        },

        getRandomColor() {
            return '#' + Math.floor(Math.random() * 16777215).toString(16);
        },


        generatorColor() {
            var mycolor = '#' + (Math.random() * 0xFFFFFF << 0).toString(16);
            return mycolor;
        },


        getRandomColorHexRgb() {
            var r = Math.floor(Math.random() * 255);
            var g = Math.floor(Math.random() * 255);
            var b = Math.floor(Math.random() * 255);
            return "rgb(" + r + "," + g + "," + b + ")";
        },
        getRandomColorHexRgbA() {
            var r = Math.floor(Math.random() * 255);
            var g = Math.floor(Math.random() * 255);
            var b = Math.floor(Math.random() * 255);
            return "rgba(" + r + "," + g + "," + b + ",0.2)";
        },
        fade(sType) {
            this.isLoading = sType;
        },

        loading() {
            this.fade(true);
            setTimeout(() => {
                this.isLoading = false;
            }, 1000); // hide the message after 3 seconds
        },

        fetchIt() {
            const userDatas = this.$getUserInfo();
            this.detailUser = userDatas.sub;
            // this.permision_role = userDatas.sub.permision_role;
            let rolePermision = [];
            userDatas.sub.permision_role.forEach(function (permision) {
                if (permision.permission.controller == 'MprDashboardController') {
                    rolePermision = permision;
                    // console.log(permision);
                }
            });

            if (!rolePermision.method_read) {
                this.$router.push("/authorized-error");
            }
            this.permision_role = rolePermision;

        },

        logout() {
            this.$logoutUser();
            this.$router.push("/");
        },

        resultError(data) {
            var count = Object.keys(data).length;
            for (var x = 0; x < count; x++) {
                var nameOb = Object.keys(data)[x];
                var objectData = data[nameOb];
                for (var y = 0; y < objectData.length; y++) {
                    this.error(objectData[y]);
                }
            }
        },



        success(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "success",
                title: kata,
            });
        },

        error(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "error",
                title: kata,
            });
        },
    },
    events: {},
    created: function () { },
    mounted() {
        this.fetchIt();
        this.loading();
        this.loadPriority();
        this.loadStatus();
        this.loadDriverFullfillment();

    },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
    visibility: hidden;
}

.blur-content {
    filter: blur(5px);
}

.chart-canvas {
    height: 300px !important;
    /* Atur tinggi sesuai kebutuhan */
    width: 100%;
    /* Pastikan lebarnya fleksibel */
}


.chart-canvas-driver-fullfilment{

    height: 500px !important;
    /* Atur tinggi sesuai kebutuhan */
    width: 100%;

}


.chart-container {
  height: 500px; /* Tinggi untuk grafik */
}

.table-container {
  overflow-x: auto;
}

.table-auto th,
.table-auto td {
  text-align: center;
}

</style>