import PengajuanMpr from "@/components/mpr/pengajuan/PengajuanMPR.vue";
import PengajuanMprCreate from "@/components/mpr/pengajuan/PengajuanCreate.vue";
import PengajuanDetail from "@/components/mpr/pengajuan/PengajuanDetail.vue";
import PengajuanEdit from "@/components/mpr/pengajuan/PengajuanEdit.vue";

let routes= [
    
    {
      path: '/',
      meta: {
        title: "Chain System",
      },
      name: "MprPengajuan",
      component: PengajuanMpr
    },
    {
      path: "add",
      meta:{
        title: "Chain System",
      },
      name: "MprPengajuanCreate",
      component:PengajuanMprCreate
    },
    {
      path: "detail/:id",
      meta:{
        title: "Chain System",
      },
      name: "MprPengajuanDetail",
      component:PengajuanDetail
    },
    {
      path: "edit/:id",
      meta:{
        title: "Chain System",
      },
      name: "PengajuanEdit",
      component:PengajuanEdit
    },
   ]
  
  export default routes
  