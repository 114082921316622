<template>
    <div>
      <!-- <div class="loader" v-if="this.isLoading"></div>
      <div class="loadertext" v-if="this.isLoading">
        {{ $t("loadingTxt") }}...
      </div> -->
      <div
        class="main-wrapper"
        
      >
        <!--begin::sidebar-->
        <sidebar-component classMenu="Dashboard"></sidebar-component>
        <!--end::sidebar-->
  
        <div class="page-wrapper">
          <!--begin::navbar-->
          <navbar-component></navbar-component>
          <!--end::navbar-->
  
          <!--begin::content-->
          <div class="page-content">
            <div
              class="
                d-flex
                justify-content-between
                align-items-center
                flex-wrap
                grid-margin
              "
            >
              <div>
                <h4 class="mb-3 mb-md-0">Dashboard KPI - {{title}}</h4>
              </div>
            </div>
  
            
              <div class="row" v-html="iframe"></div>
                <!-- <iframe title="Vendor_Analyst_Report" width="1140" height="800" src="https://app.powerbi.com/reportEmbed?reportId=2fd0b865-3695-442b-88c3-1d83513fa35a&autoAuth=true&ctid=124bede5-2b80-42b5-8bee-f720a5bdfcb9"  frameborder="0" allowFullScreen="true"></iframe> -->
            
  
           
          </div>
          <!--end::content-->
  
          <!--begin::footer-->
          <footer-component></footer-component>
          <!--end::footer-->
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import sidebarComponent from "./_partials/_sidebar";
  import navbarComponent from "./_partials/_navbar";
  import footerComponent from "./_partials/_footer";
  import moment from "moment";
  
  // import DateRangePicker from 'vue2-daterange-picker'
  // import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
  
  // import { Bar, Line as LineChartGenerator,Pie } from 'vue-chartjs'



  
  export default {
    name: "UserDashboard",
    components: {
      "sidebar-component": sidebarComponent,
      "navbar-component": navbarComponent,
      "footer-component": footerComponent,
      // Bar,LineChartGenerator,Pie,
    },
    props: {
     
      
    },
    data() {
      return {
      title:"",
      titleData:[
        {
          id:"8",
          name:'KPI Customer'
        },
        {
          id:"9",
          name:'On Time Pick up'
        },
        {
          id:"10",
          name:'On time delivery'
        },
        {
          id:"11",
          name:'POD Return'
        },
        {
          id:"12",
          name:'Zero Claim'
        },
        {
          id:"13",
          name:'Kelayakan Kendaraan'
        },
       
      ],
      
      fetchLookupCode:[],
      iframe:"",
      };
    },
    computed: {
      myStyles() {
        return {
          height: "500px",
          position: "relative",
        };
      },
    },
    watch: {},
    methods: {
      formatDate(val) {
        return val ? moment(val).format("YYYY-MM-DD") : "";
      },


      getTitleById(id) {
        const item = this.titleData.find(data => data.id === id);
        this.title= item ? item.name : null;
      },

      loadLookupCode(params){
  
        const baseURI =
          this.$settings.endPointCt + `master-lookup/index-view?unique_id=metadm&seq=`+params;
        return this.$http.get(baseURI).then((response) => {
          this.fetchLookupCode = response.data.datas.data;
          this.iframe=this.fetchLookupCode[0].value==null ? "<h1>Under Maintenance</h1><p>We're currently making some improvements. Please check back later.</p>" :this.fetchLookupCode[0].value;
        });
      },
  
      submitData() {
        this.fade(true);
    
     
        const startDate = this.formatDate(this.pickupDates.startDate);
        const endDate = this.formatDate(this.pickupDates.endDate);
        const baseURI =
          this.$settings.endPoint +
          "chart/one?company_id=" +
          this.company.company_id +
          "&start_date=" +
          startDate +
          "&end_date=" +
          endDate;
  
        return this.$http
          .get(baseURI, { timeout: 10000 })
          .then((response) => {
            const rows = response.data.datas;
  
            if (rows.labels.length) {
              // this.chartDataLine = {
              //   labels:rows.labels,
              //   datasets:rows.dataSet
              // }
            } else {
              // this.chartDataLine = {
              //   labels:[],
              //   datasets:[]
              // }
              this.availableChartDataLineAvailable = true;
            }
  
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.code == "ECONNABORTED") {
              this.error(this.$t("check_internet_connection_txt"));
            } else {
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else if (error.response.status === 401) {
                  this.$router.push("/authorized-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            }
          });
      },
      generatorColor(){
          var mycolor = '#'+(Math.random()*0xFFFFFF<<0).toString(16);
          return mycolor;
      },
  
   
  
  

 
  
  
    
 
  
      getRandomColorHexRgb() {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        return "rgb(" + r + "," + g + "," + b + ")";
      },
      getRandomColorHexRgbA() {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        return "rgba(" + r + "," + g + "," + b + ",0.2)";
      },
      fade(sType) {
        this.isLoading = sType;
      },
  
      loading() {
        this.fade(true);
        setTimeout(() => {
          this.isLoading = false;
        }, 1000); // hide the message after 3 seconds
      },
  
      fetchIt() {
      const userDatas     = this.$getUserInfo();
        this.detailUser     = userDatas.sub;
        // this.permision_role = userDatas.sub.permision_role;
        let rolePermision = [];
        userDatas.sub.permision_role.forEach(function(permision){
          if(permision.permission.controller == 'DmDashboardController' || permision.permission.controller == 'OpsDashboardController'){
            rolePermision = permision;
            // console.log(permision);
          }
        });

        if(!rolePermision.method_read){
          this.$router.push("/authorized-error");
        }
        this.permision_role = rolePermision;

      },
  
      logout() {
        this.$logoutUser();
        this.$router.push("/");
      },
  
      resultError(data) {
        var count = Object.keys(data).length;
        for (var x = 0; x < count; x++) {
          var nameOb = Object.keys(data)[x];
          var objectData = data[nameOb];
          for (var y = 0; y < objectData.length; y++) {
            this.error(objectData[y]);
          }
        }
      },

      
  
      success(kata) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          title: kata,
        });
      },
  
      error(kata) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: kata,
        });
      },
    },
    events: {},
    created: function () {},
    mounted() {
      this.fetchIt();
      this.loading();
      var params=this.$route.query.params;
      console.log(params);
      this.getTitleById(params);
      this.loadLookupCode(params);
      // this.loadData30DaysOrder();
      // this.loadData12MonthOrder();
      // this.loadData30DaysOrderByTypeTruck();
      // this.loadData12MonthOrderByTypeTruck();
    },
  };
  </script>
  <style scoped>
  button.gm-ui-hover-effect {
    visibility: hidden;
  }
  .blur-content {
    filter: blur(5px);
  }
  </style>