import AksesNotifikasi from "@/components/mpr/akses_notifikasi/AksesNotifikasi.vue";

let routes= [
    
    {
      path: '/',
      meta: {
        title: "Chain System",
      },
      name: "AksesNotifkasi",
      component: AksesNotifikasi
    },
   ]
  
  export default routes
  