import ApprovalMpr from "@/components/mpr/approval/ApprovalMPR.vue";
import DetailApprovalMpr from "@/components/mpr/approval/detailApproval.vue";
// import PengajuanMprCreate from "@/components/mpr/pengajuan/PengajuanCreate.vue";
// import PengajuanDetail from "@/components/mpr/pengajuan/PengajuanDetail.vue";
// import PengajuanEdit from "@/components/mpr/pengajuan/PengajuanEdit.vue";

let routes= [
    
    {
      path: '/',
      meta: {
        title: "Chain System",
      },
      name: "MprApproval",
      component: ApprovalMpr
    },
    
    // {
    //   path: "add",
    //   meta:{
    //     title: "Chain System",
    //   },
    //   name: "MprPengajuanCreate",
    //   component:PengajuanMprCreate
    // },
    {
      path: "detail/:id",
      meta:{
        title: "Chain System",
      },
      name: "DetailApprovalMpr",
      component:DetailApprovalMpr
    },
    // {
    //   path: "edit/:id",
    //   meta:{
    //     title: "Chain System",
    //   },
    //   name: "PengajuanEdit",
    //   component:PengajuanEdit
    // },
   ]
  
  export default routes
  