<template>
  <div>
    <div class="page-content">
      <div class="col-lg-12 col-xl-12 stretch-card">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-lg-12">
                <h4 style="margin-right: 5px" class="float-start">
                  Approval MPR
                  <span class="badge bg-primary">{{ detailData?.status }}</span>
                </h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row my-4">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="row mb-3">
                      <label class="col-sm-3 col-form-label">Request No</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          readonly
                          v-model="detailData.request_no"
                          class="form-control"
                          placeholder="Request No"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row mb-3">
                      <label class="col-sm-3 col-form-label"
                        >Request Date</label
                      >
                      <div class="col-sm-9">
                        <input
                          type="text"
                          v-bind:value="detailData.request_date"
                          disabled
                          class="form-control"
                          placeholder="Customer Team"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="row mb-3">
                      <label class="col-sm-3 col-form-label"
                        >Customer Team</label
                      >
                      <div class="col-sm-9">
                        <input
                          type="text"
                          v-bind:value="detailData.customer_team"
                          disabled
                          class="form-control"
                          placeholder="Customer Team"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-4">
                  <div class="card">
                    <div class="card-body">
                      <vue-good-table
                        title="list-user"
                        :line-numbers="true"
                        :isLoading.sync="isLoading"
                        :rows="details"
                        :columns="columns"
                        :sort-options="{
                          enabled: false,
                        }"
                        :select-options="{
                          enabled: true,
                          selectOnCheckboxOnly: true,
                          selectionText: $t('data_selected'),
                          clearSelectionText: $t('clear'),
                        }"
                        @on-selected-rows-change="selectionChanged"
                      >
                        <template slot="table-row" slot-scope="props">
                          <span v-if="props.column.field == 'actions'">
                            <a
                              href="#"
                              v-show="detailData?.status == 'Open'"
                              title="Approve"
                              @click.prevent="
                                approveDetail(props.index, props.row)
                              "
                            >
                              <i
                                class="mdi mdi-check"
                                style="font-size: 16px"
                              ></i>
                            </a>
                            <a
                              href="#"
                              v-show="detailData?.status == 'Open'"
                              title="Reject"
                              @click.prevent="
                                rejectDetail(props.index, props.row)
                              "
                            >
                              <i
                                class="mdi mdi-close"
                                style="font-size: 16px"
                              ></i>
                            </a>
                          </span>
                          <span v-else-if="props.column.field == 'request_no'">
                            {{ detailData?.request_no }}
                          </span>
                          <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                          </span>
                        </template>
                        <div slot="table-actions">
                          {{ $t("no_select_data") }}
                        </div>

                        <div slot="selected-row-actions">
                          <button
                            class="btn btn-danger btn-xs"
                            style="margin-right: 20px"
                            @click="rejectApproval"
                            v-show="detailData?.status == 'Open'"
                          >
                            <i class="mdi mdi-close"></i> Reject
                          </button>
                          <button
                            class="btn btn-success btn-xs"
                            style="margin-right: 20px"
                            :disabled="!approveActive"
                              v-show="detailData?.status == 'Open'"
                            @click="approveApproval"
                          >
                            <i class="mdi mdi-check"></i> Approve
                          </button>
                        </div>
                      </vue-good-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button
              class="btn btn-warning text-black fw-bolder btn-icon-text"
              @click="backForm"
              type="button"
            >
              <i class="link-icon" data-feather="arrow-left"></i>
              {{ $t("backMess") }}
            </button>
            &nbsp;&nbsp;&nbsp;
            <button
              class="btn btn-primary float-end btn-sm mx-1"
              @click="sendData"
              v-show="detailData?.status == 'Open'"
              type="button"
            >
              {{ $t("submitFormTxt") }}
              <i class="mdi mdi-send" style="font-size: 16px"></i>
            </button>
            <!-- <button
              class="btn btn-primary float-end btn-sm mx-1"
              @click="cancelData"
              type="button"
            >
              <i class="mdi mdi-block-helper" style="font-size: 16px"></i>
              Cancel
            </button> -->
          </div>
        </div>

        <modalRejectApprovalMPR
          :showModal="modalReject"
          @closeModal="closeMyModal"
          @submit="submitRejectData"
          :id="selectedIds"
        ></modalRejectApprovalMPR>
        <modalApproveApprovalMPR
          :showModal="modalApprove"
          @closeModal="closeMyModal"
          @submit="submitApproveData"
          :id="selectedIds"
          :data="approveData"
        ></modalApproveApprovalMPR>
      </div>
    </div>
  </div>
</template>

<script>
import modalRejectApprovalMPR from "@/components/modal/modalRejectApprovalMPR.vue";
import modalApproveApprovalMPR from "@/components/modal/modalApproveApprovalMPR.vue";
export default {
  name: "DetailApproval",
  components: {
    modalRejectApprovalMPR,
    modalApproveApprovalMPR,
  },
  data() {
    return {
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
      permision_role: [],
      langs: ["id", "en"],
      opens: "center",
      locale: {
        format: "yyyy-mm-dd",
      },
      detailData: {},
      details: [],
      columns: [
        {
          label: "Action",
          field: "actions",
        },
        {
          label: "Request No",
          field: "request_no",
        },
        {
          label: "Jenis Driver",
          field: "jenis_driver",
        },
        {
          label: "Opsi",
          field: "opsi",
        },
        {
          label: "Pengganti",
          field: "driver_replace",
        },
        {
          label: "Nopol",
          field: "vehicle_no",
        },
        {
          label: "Tipe Unit",
          field: "tipe_unit",
        },
        {
          label: "SIM",
          field: "sim",
        },
        {
          label: "Lokasi",
          field: "location",
        },
        {
          label: "Project",
          field: "project",
        },
        {
          label: "Kriteria Driver",
          field: "kriteria_driver",
        },
        {
          label: "Tgl Dibutuhkan",
          field: "need_date",
        },
        {
          label: "Catatan Ops",
          field: "remarks_request",
        },
        {
          label: "Tgl Dibutuhkan Sebelumnya",
          field: "original_need_date",
        },
        {
          label: "Prioritas",
          field: "priority",
        },
        // {
        //   label: "Alokasi Driver",
        //   field: "assign_driver",
        // },
        // {
        //   label: "Nama Driver",
        //   field: "assign_name_driver",
        // },
        // {
        //   label: "Tgl Mulai Driver Aktif",
        //   field: "assign_date",
        // },
        {
          label: "Remarks Approve",
          field: "remarks_approve",
        },
        {
          label: "Remarks Reject",
          field: "remarks_reject",
        },
      ],
      approveActive: false,
      modalReject: false,
      modalApprove: false,
      selectedIds: null,
      approveData: {
        need_date: null,
        request_date: null,
      },
    };
  },
  methods: {
    approveDetail(index, row) {
      this.selectedIds = [];
      this.selectedIds.push(row.master_mpr_detail_id);
      this.approveData.need_date = row.need_date;
      this.modalApprove = true;
    },
    rejectDetail(index, row) {
      this.selectedIds = [];
      this.selectedIds.push(row.master_mpr_detail_id);
      this.modalReject = true;
      0;
    },
    cancelData() {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataCancel"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var params = this.$onBehind(this.$route.params.id);
          let id = params[0];
          const baseURI =
            this.$settings.endPointMPR + "v1/mpr/approval/detail/" + id;
          return this.$http
            .get(baseURI)
            .then((response) => {
              let res = response.data.datas;
              this.detailData = res;
              this.details = res.details;
              this.approveData.request_date = res.request_date;
            })
            .catch((error) => {
              this.isloading = false;
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                }
              }
            });
        }
      });
    },
    sendData() {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          let valid = this.validateData();
          let payloads = [];
          if(valid){
            this.details.forEach((value)=>{
              payloads.push({
                master_mpr_detail_id: value.master_mpr_detail_id,
                need_date           : value.need_date,
                priority            : value.priority,
                original_need_date  : value.original_need_date,
                remarks_approve     : value.remarks_approve,
                remarks_reject      : value.remarks_reject,
              });
            });
            const baseURI = this.$settings.endPointMPR + "v1/mpr/approval/submit/" + this.detailData.master_mpr_id;
          this.$http
            .post(baseURI,payloads)
            .then((response) => {
              this.isLoading = false;
              if (response.data.status === 200) {
                window.location.href = "/mpr-approval";
              } else {
                this.errors = response.data.errors;
                //this.resultError(response.data.errors);
                this.loadError(this.errors.message);
              }
            })
            .catch((error) => {
              this.isloading = false;
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                }
              }
            });
            console.log(payloads);
          }
          
        }
      });
    },
    validateData() {
      let validate = true;
      this.details.forEach((value, key) => {
        if (
          (value.remarks_approve == "" && value.remarks_reject == "") ||
          (value.remarks_approve == null && value.remarks_reject == null)
        ) {
          validate = false;
          this.toastError("Line No " + (key + 1) + " Not Approved Or Rejected");
        }
      });

      return validate;
    },
    submitRejectData(ids, data) {
      let detail = this.details;

      ids.forEach(function (value) {
        detail.forEach(function (valueDetail, keyDetail) {
          if (valueDetail.master_mpr_detail_id == value) {
            let detailData = valueDetail;
            detailData.need_date = "";
            detailData.original_need_date = "";
            detailData.priority = "";
            detailData.remarks_approve = "";
            detailData.remarks_reject = data.remarks_reject;
            detail.splice(keyDetail, 1, detailData);
          }
        });
      });
      this.details = detail;
      this.modalReject = false;
    },
    submitApproveData(ids, data) {
      console.log(ids, data);
      let detail = this.details;

      ids.forEach(function (value) {
        detail.forEach(function (valueDetail, keyDetail) {
          if (valueDetail.master_mpr_detail_id == value) {
            let detailData = valueDetail;
            detailData.remarks_reject = "";
            detailData.need_date = data.need_date;
            detailData.original_need_date = data.original_need_date;
            detailData.priority = data.priority;
            detailData.remarks_approve = data.remarks_approve;
            detail.splice(keyDetail, 1, detailData);
          }
        });
      });
      this.details = detail;
      this.modalApprove = false;
    },
    closeMyModal() {
      this.modalReject = false;
      this.modalApprove = false;
    },
    rejectApproval() {
      this.modalReject = true;
    },
    approveApproval() {
      this.modalApprove = true;
    },
    selectionChanged(ev) {
      let date = null;
      let status = true;
      let selectedId = [];
      ev.selectedRows.forEach(function (e) {
        selectedId.push(e.master_mpr_detail_id);
        if (date == null) {
          date = e.need_date;
        } else {
          if (date !== e.need_date) {
            status = false;
          }
        }
      });
      this.approveData.need_date = date;
      this.approveActive = status;
      this.selectedIds = selectedId;
    },
    loadData(id) {
      const baseURI =
        this.$settings.endPointMPR + "v1/mpr/approval/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        let res = response.data.datas;
        this.detailData = res;
        this.details = res.details;
        this.approveData.request_date = res.request_date;
      });
    },

    backForm() {
      window.location.href = "/mpr-approval";
    },
    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "MprApprovalController") {
          rolePermision = permision;
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;
    },
    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },
    toastError(kata){
      this.$toast.open({
          message: kata,
          type: 'error',
          position:'top-right',
          diration:5000,
          dismissible:true
          // all of other options may go here
      });
    },
    
    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },
    cek_message(status, msg) {
      if (status == "200") {
        this.success(msg);
      }
    },
  },
  watch: {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.fetchIt();
    this.loadData(params[0]);
  },
};
</script>
