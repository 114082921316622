<template>
  <div
    class="modal fade"
    ref="modal"
    :class="{ show: active, 'd-block': active }"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Approve Approval</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            v-on:click="$emit('closeModal')"
            aria-label="btn-close"
          ></button>
        </div>

        <form
          class="forms-sample"
          method="POST"
          ref="formElement"
          @submit.prevent="submitData"
        >
          <div class="modal-body">
            <div class="mb-3">
              <h4>Setujui Pengajuan Yang dipilih ?</h4>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <div class="mb-3">
                  <label class="col-form-label">Priority</label>
                  <v-select
                    :options="['Normal','Urgent','Top Urgent']"
                    v-model="forms.priority"
                    @input="priorityChanged"
                    ></v-select>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="mb-3">
                  <label class="col-form-label">Need Date</label>
                  <Datepicker
                    v-model="forms.need_date"
                    :disabled-dates="disabledDates"
                    :bootstrap-styling="true"
                    format="dd-MM-yyyy"
                    placeholder="Please Select Date..."
                  ></Datepicker>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="mb-3">
                  <label class="col-form-label">Original Need Date</label>
                  <Datepicker
                    v-model="forms.original_need_date"
                    :disabled-dates="disabledDates"
                    :bootstrap-styling="true"
                    disabled
                    format="dd-MM-yyyy"
                    placeholder="Please Select Date..."
                  ></Datepicker>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="mb-3">
                  <label class="col-form-label">Alasan</label>
                  <textarea
                    class="form-control"
                    v-model="forms.remarks_approve"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              v-on:click="$emit('closeModal')"
            >
              Cancel
            </button>

            <div v-if="isLoading" class="d-flex align-items-center">
              <div
                class="spinner-border ms-auto"
                role="status"
                aria-hidden="true"
              ></div>
              <strong>Loading...</strong>
            </div>

            <button v-if="!isLoading" class="btn btn-primary" type="submit">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    Datepicker,
  },
  name: "modalApproveApprovalMPR",
  emits: ["closeModal"],

  props: {
    showModal: Boolean,
    id: null,
    data: null,
    // type: String,
  },
  watch: {
    showModal: {
      handler(newVal) {
        //this.loadForCarrier(this.forms.transporter)
        this.active = newVal;
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
        if(newVal){
          if(this.data?.need_date){
            this.forms.need_date = this.data.need_date;
          }
          this.calculatePriority();
        }
      },
      immediate: true,
      deep: true,
    },
    'forms.need_date':{
      handler(newVal){
        if(newVal.length != 10){
          this.forms.need_date = newVal.toISOString().split("T")[0];
          this.forms.original_need_date = this.data.need_date;
        }else{
          if(this.forms.original_need_date == this.forms.need_date){
            this.forms.original_need_date = null;
          }
          this.calculatePriority();
        }
        
      }
    }
  },
  data() {
    return {
      isLoading: false,
      active: this.showModal,
      forms: {
        priority: null,
        need_date: new Date(),
        original_need_date: null,
        remarks_approve:null,
      },
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
    };
  },
  methods: {
    calculatePriority(){
      const request_date = new Date(this.data.request_date);
      const need_date = new Date(this.forms.need_date);
      const dayDiff = this.dateDiffInDays(request_date,need_date);
      if(dayDiff <= 4){
        this.forms.priority = "Top Urgent";
      }else if(dayDiff <= 7){
        this.forms.priority = "Urgent";
      }else{
        this.forms.priority = "Normal";
      }
    },
    dateDiffInDays(a, b) {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      // Discard the time and time-zone information.
      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

      return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    },
    priorityChanged(ev){
      var update_need_date = new Date(this.data.request_date);
      let day = 0;
      if(ev == 'Normal'){
        day = 14;
      }
      if(ev == 'Urgent'){
        day = 7;
      }
      if(ev == 'Top Urgent'){
        day = 4;
      }
      update_need_date.setDate(update_need_date.getDate() + day);
      this.forms.need_date = update_need_date;
      console.log(ev,update_need_date);
    },
    submitData() {
      this.$emit("submit", this.id, this.forms);
      this.forms = {
        priority: null,
        need_date: new Date(),
        original_need_date: null,
        remarks_approve:null,
      };
    },
    resetForm() {
      this.forms = [];
    },

    resultError(data) {
      var html = "";
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          html += objectData[y] + "<br>";
        }
      }

      this.loadError(html);
    },

    loadError(pesan) {
      this.$swal({
        backdrop: true,
        title: "Warning",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = false;
        }
      });
    },

    loadSuccess(pesan) {
      this.$swal({
        backdrop: true,
        title: "Success",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = false;
        }
      });
    },
  },
  created: function () {
    //this.loadDriver();
  },
  mounted() {
    const formElement = this.$refs.formElement;
    if (formElement) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (
            mutation.type === "attributes" &&
            mutation.attributeName === "readonly"
          ) {
            const inputs = formElement.querySelectorAll(".multiselect__input");
            inputs.forEach((input) => {
              input.removeAttribute("readonly"); // Hapus readonly setiap kali diubah
            });
          }
        });
      });

      const inputs = formElement.querySelectorAll(".multiselect__input");
      inputs.forEach((input) => {
        observer.observe(input, { attributes: true });
      });
    }

    //tail(this.data);
  },
};
</script>

<style scoped>
.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 100%;
  height: 100%;
  margin-left: 0px !important;
}
</style>
