<template>
    <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div :class="{'blur-content': this.isLoading}">
      <div class="main-wrapper">
        <!--begin::sidebar-->
        <sidebar-component classMenu="TransportOrderCt"></sidebar-component>
        <!--end::sidebar-->
  
        <div class="page-wrapper">
          <!--begin::navbar-->
          <navbar-component></navbar-component>
          <!--end::navbar-->
  
          <!--begin::content-->
          <div class="page-content">
             
              <div>
                <h4 style="margin-right: 5px" class="float-start"></h4>
                <h4 class="mb-12 mb-md-0">
                    {{ $t("driver_attendance_txt") }} Recap
                </h4>
                <br/>
              </div>
  
              <div class="col-lg-12 col-xl-12 stretch-card">
                <div class="card">
                  <div class="card-header">
                    <div class="row">
                        <div class="col-lg-4">
                        <!--Datepicker
                                    v-model="filtertanggal"
                                    :bootstrap-styling=true
                                    format="dd-MM-yyyy"
                                    placeholder="Please Select Date..."
                         ></Datepicker-->       
                         <date-range-picker style="width: 100%" 
                                            control-container-class="form-control" :locale-data="locale"
                                            v-model="dateFilter" :opens="opens" :singleDatePicker="false"
                                            :timePicker24Hour="true" :timePicker="true" :showWeekNumbers="false">
                         </date-range-picker>                  
                      </div>   
                    
                    <div class="col-lg-4">
                      <button class="btn btn-lg btn-success" @click="loadItems()">Show Recap</button>
                      <span style="width:5px">{{" "}}</span>
                      <button v-if="permision_role.method_custom2" class="btn btn-lg btn-warning"
                        style="margin-right: 5px" @click.prevent="newData()">
                        New Recap
                      </button>
                    </div>      
                    
                  </div>
                               
                    
                  </div>
  
                  <div class="card-body">
  
                    <div class="table-responsive" style="overflow-y: auto;">
                      <div class="col-sm-12">
                            
                          <vue-good-table
                            title="list-order-ct"
                            mode="remote"
                            :totalRows="totalRecords"
                            :line-numbers="true"
                            :isLoading.sync="isLoading"
                            :pagination-options="{
                              enabled: false,
                            }"
                            
                            :rows="rows"
                            :columns="columns"
                          >
                        
  
                          <template slot="table-row" slot-scope="props">
                              <span v-if="props.column.field == 'control'">
                                
                                    <a style="color: #ab0635;text-decoration: none;"
                                      v-if="permision_role.method_custom1"
                                      title="View"
                                      @click.prevent="
                                        viewData(props.row)
                                      "
                                    >
                                     <i class="mdi mdi-eye" style="font-size:16px"></i>
                                      
                                    </a>
  
                                    
                                    <a style="color: #ab0635;text-decoration: none;"
                                      v-if="permision_role.method_custom2"
                                      title="Edit"
                                      @click.prevent="editData(props.row)"
                                    >
                                      <i class="mdi mdi-square-edit-outline" style="font-size:16px"></i>
                                      
                                    </a>


                                    <a style="color: #ab0635;text-decoration: none;"
                                      v-if="permision_role.method_custom1"
                                      title="Edit"
                                      @click.prevent="downloadData(props.row)"
                                    >
                                      <i class="mdi mdi-download" style="font-size:16px"></i>
                                      
                                    </a>
        
                              </span>
                              <span v-else-if="props.column.field == 'status'">
                                <b>{{props.row.create_by == "system"?"Updated":"Submited"}}</b>
                              </span>
                              <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                              </span>
                            </template>
                          </vue-good-table>
   
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
  
              
          
          </div>
          <!--end::content-->
  
          <modal-recap-detail :showModal="showModalRecap" :data="this.dataModal" @closeModal="closeMyModal">
          </modal-recap-detail>
  
  
          <!--begin::footer-->
          <footer-component></footer-component>
          <!--end::footer-->
        </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import sidebarComponent from "@/components/_partials/_sidebar";
  import navbarComponent from "@/components/_partials/_navbar";
  import footerComponent from "@/components/_partials/_footer";
  //import Datepicker from "vuejs-datepicker";
  import ModalRecapUtility from "@/components/modal/modalRecapUtility.vue";
  import DateRangePicker from "vue2-daterange-picker";
  import moment from 'moment';
  
  export default {
    name: "DriverAttendance",
    components: {
      "sidebar-component": sidebarComponent,
      "navbar-component": navbarComponent,
      "footer-component": footerComponent,
      "modal-recap-detail": ModalRecapUtility,
      //Datepicker,
      DateRangePicker,
    },
    data() {
      const startDate = moment().clone().startOf('month').format('YYYY-MM-DD'); // new Date();
      const endDate = new Date();

      return {
        opens:"center",
        locale:{
                format:"yyyy-mm-dd",            
            },
        showModalRecap:false,
        dataModal: {},
        maxToasts: 100,
        filtertanggal: new Date(),
        dateFilter: {
                startDate,
                endDate,
            },
        position: "up right",
        closeBtn: true,
        isLoading: false,
        errors: [],
        permision_role:[],
        langs: ["id", "en"],
        detailUser: [],
        detailDivisi: [],
        totalRecords: 0,
        serverParams: {
          // columnFilters: {},
          sort: {
            field: "",
            type: "",
          },
          page: 1,
          per_page: 1000,
        },
        columns: [
          {
            label: "Date",
            field: "date",
            sortable: false,
          },
          {
            label: "Active",
            field: "active",
            sortable: false,
          },
          {
            label: "No Driver",
            field: "no_driver", 
            sortable: false,          
          },
          {
            label: "Idle",
            field: "idle",
            sortable: false,
          },
          {
            label: "IS",
            field: "is",
            sortable: false,
          },

          {
            label: "SERVICE",
            field: "service",
            sortable: false,
          },
        
        
          {
            label: "Status",
            field: "status",
            sortable: false,
          },
          {
            label: "Control",
            field: "control",
            sortable: false,
          },  
        ],
        rows: [],
      };
    },
    watch: {},
    computed: {
    },
    methods: {  
      closeMyModal() {
        this.showModalRecap = false;
        this.loadItems();
      },
      fetchIt() {
        const userDatas     = this.$getUserInfo();
        this.detailUser     = userDatas.sub;
        // this.permision_role = userDatas.sub.permision_role;
        let rolePermision = [];
        userDatas.sub.permision_role.forEach(function(permision){
          if(permision.permission.controller == 'TruckUtilityDetailController'){
            rolePermision = permision;
          }
        });
  
        if(!rolePermision.method_custom1){
          this.$router.push("/authorized-error");
        }
        this.permision_role = rolePermision;
  
        if(this.$route.query.msg){
          if(this.$route.query.status == 200){
            this.success(this.$route.query.msg)
          }else{
            this.error(this.$route.query.msg)
          }
  
        }
  
      },
  
    
      updateFormatDate(date){
        let date_format = this.$moment(date).format("dd mmm yyyy");
        return date_format;
      },


    downloadData(row) {
      console.log(row.date);
      this.isLoading = true;
      const baseURI = this.$settings.endPointCt + "truck-utility-detail/download-uap-report";

      var file_name = `uap_report_${row.date}.xlsx`;

      return this.$http
        .get(
          baseURI
          +
          `?tgl=${row.date}`,
          { responseType: "blob" }
        )
        .then((response) => {

          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        });
    },
  
      editData(row) {
        this.$root.$router.push({
              path: `/absensidriver/${row.date}`,
          })
        // var params = this.$onRandom(row.transport_order_header_id);
        // window.location.href = "/ct-orders/edit/" + params;
      },

      viewData(row) {
        const baseURI = this.$settings.endPointCt + "truck-utility-detail/view-recap/"+row.date; 
        this.$http
          .get(baseURI, { timeout: 10000 })
          .then((response) => {
            
              this.dataModal = {
                date: row.date,
                detail:response.data.datas
              }
              this.showModalRecap = true;

                            
            //this.statusDetail(response.datas);
          });

          // this.showModalRecap = true;
          //     this.dataModal = {
          //       date: row.date
          //     }
        
      },
  
      newData() {
        let tanggal = this.filtertanggal.toISOString().split('T')[0]
        this.$root.$router.push({
              path: `/absensidriver/${tanggal}`,
          })
         //   window.location.href = "/absensidriver";
      },

      detailData() {
            window.location.href = "/truckUtilitationReport";
      },
   
  
      // load items is what brings back the rows from server
      loadItems() {
        let dataSend = {
                start: moment(String(this.dateFilter.startDate,)).format('YYYY-MM-DD'), //this.dateFilter.startDate,  // moment(String(this.forms.join_date)).format('YYYY-MM-DD');
                end: moment(String(this.dateFilter.endDate,)).format('YYYY-MM-DD'),
            };
        //let tanggal = this.filtertanggal.toISOString().split('T')[0]
        this.isLoading = true;
        const baseURI = this.$settings.endPointCt + "absence-recap?start="+ dataSend.start + "&end="+dataSend.end;
        
        return this.$http
          .get(
             baseURI,
             // +
             // `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&company_name=${company_name}&transport_order_template_tonnage=${transport_order_template_tonnage}&transport_order_template_cubic=${transport_order_template_cubic}&transport_order_template_description=${transport_order_template_description}&order_status=${this.serverParams.columnFilters.order_status}&order_no=${this.serverParams.columnFilters.order_no}&postpone_time=${this.serverParams.columnFilters.postpone_time}&dest_name=${this.serverParams.columnFilters.dest_name}&order_date=${date_order}&pick_plan=${pick_plan}&dest_plan=${dest_plan}`,
            { timeout: 10000 }
          )
          .then((response) => { 
            this.rows = response.data.datas;
            this.totalRecords = response.data.datas.total;
            this.isLoading = false;
          }).catch(error => {
            this.isLoading = false;
            if(error.code == "ECONNABORTED"){
              this.error(this.$t("check_internet_connection_txt"));
            }else{
  
              if (error.response) {
                if(error.response.status === 422) {
                        this.errors = error.response.data.errors;
                        this.resultError(error.response.data.errors);
                }else if (error.response.status === 500) {
                    this.$router.push('/server-error');
                }else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                }else{
                    this.$router.push('/page-not-found');
                }
              }
  
            }
  
          });
      },
  
    
      resultError(data) {
        var count = Object.keys(data).length;
        for (var x = 0; x < count; x++) {
          var nameOb = Object.keys(data)[x];
          var objectData = data[nameOb];
          for (var y = 0; y < objectData.length; y++) {
            this.error(objectData[y]);
          }
        }
      },
  
      success(kata) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          title: kata,
        });
      },
  
      success2(msg){
        // let pesan="";
        // msg.forEach((item) => {
        //     pesan=pesan+"<p>* "+item+"</p>";
        // });
  
        this.$swal({
          icon: 'info',
          backdrop:true,
          title: "Information",
          html:msg,
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
  
        
      },
    
      loadingSubmiting(){ 
  
        this.$swal({
          icon: 'warning',
          backdrop:true,
          title: "Please wait",
          html:'<h2>SUBMITTING ORDER...</h2>',
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
  
      },      
  
      error(kata) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: kata,
        });
      },
  
      fade(sType) {
        this.isLoading = sType;
      },
  
      loading() {
        this.fade(true);
        setTimeout(() => {
          this.isLoading = false;
        }, 1000); // hide the message after 3 seconds
      },
  
    },
    events: {},
    created: function () {},
    mounted() {
      this.fetchIt();
      this.loading();
      this.loadItems();
  
   
    },
  };
  </script>
  <style scoped>
  .blur-content{
    filter: blur(5px); 
    pointer-events: none;
  }
  .card-body {
      padding: 5px 1.5rem 1rem;
  }
  .vgt-table th {
      text-align: left !important;
  }
  .toggleFilterClass {
        display: none !important;
    }
  </style>