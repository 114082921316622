import Vue from 'vue'
import App from './App.vue'
import VueSweetalert2 from 'vue-sweetalert2';
import Axios from 'axios';
import vueJquery from 'vue-jquery';
import VueEvents from 'vue-events';
import accounting from 'accounting';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import VueGoodTablePlugin from 'vue-good-table';
import moment from 'moment';
import money from 'v-money';
import VModal from 'vue-js-modal';
import router from "./routes";
import store from "./store";
import messages from "@/middleware/i18n";
import VueI18n from "vue-i18n";
import {onRandom,onBehind} from "@/middleware/hadish";
import settings from '@/middleware/app';
import addFunction from '@/middleware/addFunction';
import {getAuthToken,getUserInfo,logoutUser,setAuthToken,setAuthLang} from '@/middleware/auth';
import * as VueGoogleMaps from "vue2-google-maps" 
import vSelect from 'vue-select'
import 'vue-swatches/dist/vue-swatches.css'
import VSwatches from 'vue-swatches';
import { Loader } from '@googlemaps/js-api-loader';
import { Vue2TinymceEditor } from "vue2-tinymce-editor";
import DisableAutocomplete from 'vue-disable-autocomplete';
import Vidle from 'v-idle';
import VueMeta from 'vue-meta'
// import { clearSessionChainToken,clearSessionCtToken,clearSessionGtToken,clearSessionFpToken } from "@/middleware/setBackendSessions";


// import 'vue-swatches/dist/vue-swatches.css';

moment.locale('en');

require('es6-promise').polyfill();
Vue.config.productionTip = false;
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
Vue.use(DisableAutocomplete);
Vue.use(VueSweetalert2);
Vue.use(VueToast);
Vue.use(vueJquery);
Vue.use(VueEvents);
Vue.use(VueGoodTablePlugin);
Vue.use(money, {precision: 4});
Vue.use(Vidle);
Vue.use(VModal,{
  dynamicDefaults: {
    draggable: true,
    resizable: true,
    height: 'auto',
    dialog:true,
  }
})
Vue.use(VueI18n);
Vue.component('v-select', vSelect);
Vue.component('v-swatches',VSwatches);
Vue.component('vue2-tinymce-editor',Vue2TinymceEditor);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBBB7iiPJyNzMyB0eIhEZ_cOi0L1K7tUaI",
    libraries: "places",
    installComponents: true
  }
});

const loaderMaps = new Loader({
  apiKey: "AIzaSyBBB7iiPJyNzMyB0eIhEZ_cOi0L1K7tUaI",
  version: "weekly",
  libraries: ["places"]
});

Vue.filter('format_number', function (value){
  return parseInt(value).toLocaleString()
})

// global variable
Vue.prototype.$loaderMaps       = loaderMaps;
Vue.prototype.$http             = Axios;
Vue.prototype.$accounting       = accounting;
Vue.prototype.$moment           = moment;
Vue.prototype.$settings         = settings;
// Vue.prototype.$i18n = i18n;
Vue.prototype.$addFunction      = addFunction;
Vue.prototype.$getAuthToken     = getAuthToken;
Vue.prototype.$getUserInfo      = getUserInfo;
Vue.prototype.$logoutUser       = logoutUser;
Vue.prototype.$setAuthToken     = setAuthToken;
Vue.prototype.$setAuthLang      = setAuthLang;
Vue.prototype.$onRandom         = onRandom;
Vue.prototype.$onBehind         = onBehind;

const token = getAuthToken();
if (token) {
  setAuthToken(token);
}

Axios.interceptors.response.use(function(response){
  return response;
},function(error){
  if (error.response) {
    if (error.response.status === 401) {
      logoutUser();
      router.push("/");
    }else if (error.response.status === 403){
      router.push("/authorized-error");
    }else{
      return Promise.reject(error);
    }
  }else{
    return Promise.reject(error);
  }
});


let lang    = localStorage.Lang;

if (localStorage.getItem("Lang") === null) {
  localStorage.Lang   = "id";
  localStorage.setItem('Lang', "id")
  lang           = "id";
  setAuthLang("id");
  window.location.href=process.env.VUE_APP_ENPOINT_APP;
}


const i18n = new VueI18n({
    locale: lang,
    fallbackLocale: 'en',
    localeDir: 'locales',
    enableInSFC: true,
    messages,
});




const timeoutInMS = 3600000 // 1 Jam -> 15 * 60 * 1000
let timeoutId;


function handleInactive() {
  // Here you want to logout a user and/or ping your token
  console.log("logout automatically")
  
  const baseURI = settings.endPoint + "user/logout";
  Axios.get(baseURI, { timeout: 3600000 }).then((response) => {
    console.log(response);
  });

  // clearSessionChainToken(localStorage.getItem(settings.jwtSecret));

  // clearSessionCtToken(localStorage.getItem(settings.jwtSecret));

  // clearSessionGtToken(localStorage.getItem(settings.jwtSecret));

  // clearSessionFpToken(localStorage.getItem(settings.jwtSecret));

  logoutUser();
  
  router.push("/");
}

function startTimer() { 
  // setTimeout returns an ID (can be used to start or clear a timer)
  timeoutId = setTimeout(handleInactive, timeoutInMS);
}

function resetTimer() { 
  clearTimeout(timeoutId);
  startTimer();
}

function setupTimers () {
  document.addEventListener("keypress", resetTimer, false);
  document.addEventListener("mousemove", resetTimer, false);
  document.addEventListener("mousedown", resetTimer, false);
  document.addEventListener("touchmove", resetTimer, false);
  startTimer();
}


Vue.prototype.$setupTimers             = setupTimers;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");


// Vue.config.productionTip = false

// new Vue({
//   render: h => h(App),
// }).$mount('#app')
