var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"main-wrapper"},[_c('sidebar-component',{attrs:{"classMenu":"Dashboard"}}),_c('div',{staticClass:"page-wrapper"},[_c('navbar-component'),_c('div',{staticClass:"page-content"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('Doughnut',{attrs:{"chart-options":{
                                    plugins: {
                                        title: {
                                            display: true,
                                            text: 'Priority in Progress' // Judul grafik
                                        }
                                    },
                                    responsive: true,
                                    maintainAspectRatio: false
                                },"chart-data":_vm.chartDataPriority,"chart-id":'doughnut-chart',"dataset-id-key":'label',"plugins":[],"css-classes":'',"styles":{},"width":400,"height":400}})],1)])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('Bar',{staticClass:"chart-canvas",attrs:{"chart-options":{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    scales: {
                                        x: { stacked: true },
                                        y: { stacked: true }
                                    },
                                    plugins: {
                                        title: {
                                            display: true,
                                            text: 'Status'
                                        }
                                    }
                                },"chart-data":_vm.chartDataStatus}})],1)])])]),_c('div',{staticClass:"row",staticStyle:{"margin-top":"10px"}},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"chart-and-table"},[_c('div',{staticClass:"chart-container"},[(_vm.chartDataDriverFullfiment.labels.length)?_c('Bar',{staticClass:"chart-canvas-driver-fullfilment",attrs:{"chart-options":_vm.chartOptionsDriverFullfilment,"chart-data":_vm.chartDataDriverFullfiment}}):_c('p',{staticClass:"text-center text-gray-500"},[_vm._v("Loading chart...")])],1),_c('div',{staticClass:"table-container mt-4"},[_c('table',{staticClass:"table-auto w-full border-collapse border border-gray-300"},[_vm._m(1),_c('tbody',_vm._l((_vm.fetchDriverFullfilment),function(item,index){return _c('tr',{key:index},[_c('td',{staticClass:"border border-gray-300 px-4 py-2"},[_vm._v(_vm._s(item.type_truck))]),_c('td',{staticClass:"border border-gray-300 px-4 py-2"},[_vm._v(_vm._s(item.total)+" ")]),_c('td',{staticClass:"border border-gray-300 px-4 py-2"},[_vm._v(_vm._s(item.driverSettle))]),_c('td',{staticClass:"border border-gray-300 px-4 py-2"},[_vm._v(_vm._s(item.driverRequest))]),_c('td',{staticClass:"border border-gray-300 px-4 py-2"},[_vm._v(_vm._s(item.nonRequest))])])}),0)]),(!_vm.fetchDriverFullfilment.length)?_c('p',{staticClass:"text-center text-gray-500 mt-4"},[_vm._v(" No data available. ")]):_vm._e()])])])])])])]),_c('footer-component')],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-between align-items-center flex-wrap grid-margin"},[_c('div',[_c('h4',{staticClass:"mb-3 mb-md-0"},[_vm._v("Dashboard MPR")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"bg-gray-100"},[_c('tr',[_c('th',{staticClass:"border border-gray-300 px-4 py-2"},[_vm._v("Type Truck")]),_c('th',{staticClass:"border border-gray-300 px-4 py-2"},[_vm._v("Sum Of Unit")]),_c('th',{staticClass:"border border-gray-300 px-4 py-2"},[_vm._v("Sum Of Driver Settle")]),_c('th',{staticClass:"border border-gray-300 px-4 py-2"},[_vm._v("Sum Of Driver Request")]),_c('th',{staticClass:"border border-gray-300 px-4 py-2"},[_vm._v("Sum Of Non Request ")])])])
}]

export { render, staticRenderFns }